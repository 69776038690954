import { initToken } from 'api/apiEnv';
import { api_login } from 'api/login';
import { errorNotification, successNotification } from 'hooks/notifications';
import { FETCH_LOGIN_ERROR, FETCH_LOGIN_START, FETCH_LOGIN_SUCCESS } from '../actionTypes';
import { fetchError, fetchStart, fetchSuccess } from './fetchStatuses';

export function fetchLogin(data, currentRole) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_LOGIN_START))
        try {
            const token = await api_login.Login(data, () => dispatch(fetchError(FETCH_LOGIN_ERROR)))
            dispatch(fetchSuccess(FETCH_LOGIN_SUCCESS, {
                access_token: token.data.access_token,
                refresh_token: token.data.refresh_token,
                role: token.data.user.role.name
            }))
            initToken()
        } catch (e) {
            dispatch(fetchError(FETCH_LOGIN_ERROR, e))
        }
    }
}

export function fetchPasswordRecovery(email) {
    return async dispatch => {
        try {
            await api_login.PasswordRecovery(email)
        } catch (e) {
            if (e.response.status === 404) errorNotification('Пользователь не найден', 'Пользователь с таким email не найден.')
        }
    }
}

export function fetchPasswordReset(data) {
    return async dispatch => {
        try {
            await api_login.ResetPassword(data)
            successNotification('Пароль успешно изменен', 'Пароль успешно изменен. Теперь вы может им пользоваться')
            return 'success'
        } catch (e) {
            errorNotification('Возникла ошибка', '')
        }
    }
}

