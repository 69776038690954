import { Form, notification } from 'antd'
import deleteIcon from 'assets/images/icons/delete.svg'
import downloading from 'assets/images/icons/downloading.svg'
import editPen from 'assets/images/icons/edit_pen.svg'
import UpdateOrderPriceForm from 'components/components/forms/updateOrder/UpdateOrderPriceForm'
import UpdateOrderCommentForm from 'components/components/forms/updateOrderComment/UpdateOrderCommentForm'
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton'
import Selector from 'components/shared/selector/Selector'
import Table from 'components/shared/table/Table'
import InfoBlock from 'components/ui/infoBlock/InfoBlock'
import { adminListOrderStatuses } from 'data/list'
import { ordersStatus } from 'data/statuses'
import { documentsInOrderTable, productsInOrderTable } from 'data/tableHeader'
import { documentsInOrderTableWidth, productsInOrderTableWidth } from 'data/tableWidth'
import { createFio } from 'hooks/createFio'
import { warningNotification } from 'hooks/notifications'
import { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { SET_VISIBLE_MODAL } from 'store/actionTypes'
import { fetchAcceptVerificationUploadTrackFile, fetchOrderDeactivate, fetchOrderDistribute, fetchRemoveCodeFiles, fetchRemoveTrackFiles, fetchUpdateOrder } from 'store/actions/admin/orders'

const MainInfo = ({
    order_by_id_data,
    fetchOrderDistribute,
    fetchOrderDeactivate,
    fetchUpdateOrder,
    fetchAcceptVerificationUploadTrackFile,
    setVisible,
    fetchRemoveTrackFiles,
    fetchRemoveCodeFiles
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm()
    const [editPrice, setEditPrice] = useState(false)
    const [editCommissionPrice, setEditCommissionPrice] = useState(false)
    const [editAdditionalComment, setEditAdditionalComment] = useState(false)
    const fileInputRef = useRef();

    const createTableData = () => {
        return order_by_id_data.products.map(el => (
            [
                {
                    type: 'text',
                    content: el.material || '-'
                },
                {
                    type: 'text',
                    content: el.color || '-'
                },
                {
                    type: 'text',
                    content: el.infill || '-'
                },
                {
                    type: 'text',
                    content: el.printer || '-'
                },
                {
                    type: 'text',
                    content: el.quantity || '-'
                },
                // {
                //     type: 'text',
                //     content: el.price || '-'
                // },
                {
                    type: 'actions',
                    content: el.link_model !== '' && <a href={el.link_model} download target="_blank"><img src={downloading} alt="downloading" /></a>
                },
            ]
        ))
    }

    const createDocumentsTableData = () => {
        const docs = []
        order_by_id_data.code_files.forEach(el => (docs.push({
            type: 'code_files',
            data: el
        })))

        order_by_id_data.track_files.forEach(el => (docs.push({
            type: 'track_files',
            data: el
        })))
        
        return docs.map(el => (
            [
                {
                    type: 'text',
                    content: el.data.media.title || '-'
                },
                {
                    type: 'text',
                    content: el.type === 'code_files' ? 'Штрих-код' : 'Накладная'
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <a href={el.data.media.link} download target="_blank"><img src={downloading} alt="downloading" /></a>
                        <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            onClick={() => warningNotification(api, async () => {
                                if(el.type === 'code_files') {
                                    fetchRemoveCodeFiles(order_by_id_data.id, [el.data.id])
                                } else {
                                    fetchRemoveTrackFiles(order_by_id_data.id, [el.data.id])
                                }
                                api.destroy()
                            }, 'удаление документа.')} />
                    </div>
                },
            ]
        ))
    }

    const editStatus = (newStatus) => {
        const i = adminListOrderStatuses.filter(el => el.title === newStatus)[0].status
        const data = {
            status: i
        }
        fetchUpdateOrder(order_by_id_data.id, data)
    }

    const handleChangeFiles = async (event, finalFunction) => {
        let data = new FormData();
        data.append('track_file', event.target.files[0]);
        finalFunction(data)
    }
    
    return (
        <>
            {contextHolder}
            <InfoBlock
                style={{ marginTop: 24, marginBottom: 24, gap: 10 }}
                className="infoBlock twoRow"
            >
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Заказчик</p>
                    <p className='p14_400 mainText p_mb16'>{createFio(order_by_id_data.customer)}</p>
                    <p className='p12_400 mainText p_mb8'>Email заказчика</p>
                    <p className='p14_400 mainText p_mb16'>{order_by_id_data.customer.email || '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Номер телефона заказчика</p>
                    <p className='p14_400 mainText p_mb16'>{order_by_id_data.customer.phone || '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Дополнительная информация</p>
                    {editAdditionalComment
                        ? <UpdateOrderCommentForm
                            initial={{
                                additional_comment: order_by_id_data.additional_comment
                            }}
                            onSave={() => setEditAdditionalComment(false)}
                        />
                        : <div style={{ display: 'flex', alignItems: 'flex-start', gap: 10, width: '95%' }}>
                            <p className='p14_400 mainText '>{order_by_id_data.additional_comment || '-'}</p>
                            <img
                                src={editPen}
                                alt='editPen'
                                width={15}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (!editPrice) {
                                        setEditAdditionalComment(true)
                                    } else {
                                        form.submit()
                                    }
                                }}
                            />
                        </div>
                    }
                </div>
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Цена</p>
                    {editPrice
                        ? <UpdateOrderPriceForm
                            form={form}
                            name={"price"}
                            initial={{
                                price: order_by_id_data.price
                            }}
                            onSave={() => setEditPrice(false)}
                        />
                        : <div className='p_mb16' style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <p className='p14_400 mainText '>{order_by_id_data.price || '-'}</p>
                            {/* {(order_by_id_data.status === 'DISTIBUTING'|| order_by_id_data.status === "CREATED") && */}
                            <img
                                src={editPen}
                                alt='editPen'
                                width={15}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (!editPrice) {
                                        setEditPrice(true)
                                    } else {
                                        form.submit()
                                    }
                                }}
                            />
                            {/* } */}
                        </div>
                    }

                    <p className='p12_400 mainText p_mb8'>Цена для исполнителя</p>
                    {editCommissionPrice
                        ? <UpdateOrderPriceForm
                            form={form}
                            initial={{
                                commission_price: order_by_id_data.commission_price
                            }}
                            onSave={() => setEditCommissionPrice(false)}
                            name={"commission_price"}
                            max={order_by_id_data.price}
                        />
                        : <div className='p_mb16' style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <p className='p14_400 mainText '>{order_by_id_data.commission_price ? (Math.round(order_by_id_data.commission_price * 10) / 10).toFixed(2) : '0'}</p>
                            {/* {(order_by_id_data.status === 'DISTIBUTING' || order_by_id_data.status === "CREATED") && */}
                            <img
                                src={editPen}
                                alt='editPen'
                                width={15}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (!editCommissionPrice) {
                                        setEditCommissionPrice(true)
                                    } else {
                                        form.submit()
                                    }
                                }}
                            />
                            {/* } */}
                        </div>
                    }
                    <p className='p12_400 mainText p_mb8'>Ожидаемая длительность исполнения, мин</p>
                    <p className='p14_400 mainText p_mb16'>{String(order_by_id_data.process_time) || '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Статус</p>
                    <Selector
                        list={adminListOrderStatuses}
                        activeItem={ordersStatus[order_by_id_data.status].title}
                        setActiveSelect={editStatus}
                        style={{
                            backgroundColor: ordersStatus[order_by_id_data.status].bg
                        }}
                    />
                    <p className='p12_400 mainText p_mb8 p_mt16'>Трек-номер</p>
                    <p className='p14_400 mainText'>{order_by_id_data.track_num || '-'}</p>
                </div>
            </InfoBlock>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 24, marginBottom: 12 }}>
                <p className='p18_500 mainText'>Документы</p>
                <SimpleButton
                    title={'Добавить документ'}
                    className="greenButton"
                    onClick={() => setVisible({ id: order_by_id_data.id })}
                />
            </div>
            <Table
                header={documentsInOrderTable}
                width={documentsInOrderTableWidth}
                data={createDocumentsTableData()}
            />
            <p className='p18_500 mainText p_mb8 p_mt24'>Заказ</p>
            <Table
                header={productsInOrderTable}
                width={productsInOrderTableWidth}
                data={createTableData()}
            />
            <div className='twoButton' style={{ marginTop: 16 }}>
                {order_by_id_data.status === 'CREATED' &&
                    <div>
                        <SimpleButton
                            title={'Отправить на распределение'}
                            className="blueButton"
                            onClick={() => warningNotification(api, async () => {
                                await fetchOrderDistribute(order_by_id_data.id)
                                api.destroy()
                            }, 'отправку заказа на распределение.')}
                        />
                    </div>
                }
                {(order_by_id_data.status !== 'COMPLITED' && order_by_id_data.status !== 'REJECTED') &&
                    <div className={order_by_id_data.status === 'CREATED' ? 'twoButton_item' : ''}>
                        <SimpleButton
                            title={'Деактивировать заказ'}
                            className='redButton'
                            onClick={() => warningNotification(api, async () => {
                                await fetchOrderDeactivate(order_by_id_data.id)
                                api.destroy()
                            }, 'деактивацию заказа.')}
                        />
                    </div>
                }
            </div>
        </>
    )
}

function mapStateToProps(state) {
    return {
        order_by_id_data: state.adminOrderById.order_by_id_data,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchOrderDistribute: (order_id) => dispatch(fetchOrderDistribute(order_id)),
        fetchOrderDeactivate: (order_id) => dispatch(fetchOrderDeactivate(order_id)),
        fetchUpdateOrder: (order_id, data) => dispatch(fetchUpdateOrder(order_id, data)),
        fetchAcceptVerificationUploadTrackFile: (order_id, track_file) => dispatch(fetchAcceptVerificationUploadTrackFile(order_id, track_file)),
        setVisible: (data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'addDocumentsOnOrder', data }),
        fetchRemoveTrackFiles: (order_id, data) => dispatch(fetchRemoveTrackFiles(order_id, data)),
        fetchRemoveCodeFiles: (order_id, data) => dispatch(fetchRemoveCodeFiles(order_id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainInfo)