import { notification } from 'antd';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import { createQuerySecondType } from 'hooks/createQuery';
import { warningNotification } from 'hooks/notifications';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { CLOSE_MODAL } from 'store/actionTypes';
import { fetchAllOperations, fetchOperationComplete, fetchOperationReject } from 'store/actions/admin/operations';

const OperationResponse = ({
    data,
    fetchOperationComplete,
    fetchOperationReject,
    fetchAllOperations,
    setVisible
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div>
            {contextHolder}
            <InfoBlock
                style={{ marginBottom: 24 }}
                className="infoBlock twoRow"
            >
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Email пользователя</p>
                    <p className='p14_400 mainText'>{data.user.email || '-'}</p>
                </div>
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Сумма</p>
                    <p className='p14_400 mainText'>{data.amount || '-'}</p>
                </div>
            </InfoBlock>
            <InfoBlock
                className="infoBlock twoRow"
            >
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Название организации</p>
                    <p className='p14_400 mainText p_mb16'>{data.user.organization_name || '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Юридический адрес</p>
                    <p className='p14_400 mainText p_mb16'>{data.user.legal_address || '-'}</p>
                    <p className='p12_400 mainText p_mb8'>ИНН</p>
                    <p className='p14_400 mainText p_mb16'>{data.user.inn || '-'}</p>
                    <p className='p12_400 mainText p_mb8'>КПП</p>
                    <p className='p14_400 mainText p_mb16'>{data.user.kpp || '-'}</p>
                    <p className='p12_400 mainText p_mb8'>ОГРН</p>
                    <p className='p14_400 mainText'>{data.user.ogrnip || '-'}</p>
                </div>
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Название банка</p>
                    <p className='p14_400 mainText p_mb16'>{data.user.bank_name || '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Расчетный счет</p>
                    <p className='p14_400 mainText p_mb16'>{data.user.bank_account || '-'}</p>
                    <p className='p12_400 mainText p_mb8'>БИК банк</p>
                    <p className='p14_400 mainText p_mb16'>{data.user.bic || '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Корреспондентский счет банка</p>
                    <p className='p14_400 mainText'>{data.user.correspondent_account || '-'}</p>
                </div>
            </InfoBlock>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 15,
                    justifyContent: 'center',
                    marginTop: 25
                }}
            >
                <SimpleButton
                    title={'Подтвердить'}
                    className="whiteButton"
                    onClick={() => warningNotification(api, async () => {
                        await fetchOperationComplete(data.id)
                        await fetchAllOperations(
                            Object.fromEntries([...searchParams]).page,
                            50,
                            createQuerySecondType(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order)
                        )
                        api.destroy()
                        setVisible()
                    }, ', что данная сумма фактически отправлена в бухгалтерию на выплату и деньги будут начислены исполнителю по его реквизитам')}
                />
                <SimpleButton
                    title={'Отклонить'}
                    className="redButton"
                    onClick={() => warningNotification(api, async () => {
                        await fetchOperationReject(data.id)
                        await fetchAllOperations(
                            Object.fromEntries([...searchParams]).page,
                            50,
                            createQuerySecondType(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order)
                        )
                        api.destroy()
                        setVisible()
                    }, 'отклонение операции')}
                />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        fetchOperationComplete: (operation_id) => dispatch(fetchOperationComplete(operation_id)),
        fetchOperationReject: (operation_id) => dispatch(fetchOperationReject(operation_id)),
        fetchAllOperations: (page, size, params) => dispatch(fetchAllOperations(page, size, params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperationResponse)