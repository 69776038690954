import { FullscreenControl, GeolocationControl, Map, Placemark, SearchControl, TypeSelector } from '@pbe/react-yandex-maps';
import { Form, Input, InputNumber } from 'antd';
import FormButton from 'components/shared/buttons/formButton/FormButton';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchCreateOrder } from 'store/actions/admin/orders';

function CreateOrderForm({
    fetchCreateOrder
}) {
    const [coords, setCoords] = useState(null)
    const navigate = useNavigate()

    const onFinish = async (values) => {
        // fetchCreateOrder(values)
        if (coords) {
            values.latitude = coords[0]
            values.longitude = coords[1]
        }
        fetchCreateOrder(values)
            .then(res => {
                if (res) navigate(`/admin/orders`)
            })
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            style={{ marginTop: 16 }}
        >
            <Form.Item
                label={'Внешний id'}
                name="external_id"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <InputNumber placeholder='Введите внешний id' />
            </Form.Item>
            <Form.Item
                label={'Id заказчика'}
                name="customer_id"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите id заказчика' />
            </Form.Item>
            <Form.Item
                label={'Стоимость'}
                name="price"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <InputNumber placeholder='Введите стоимость' />
            </Form.Item>
            <div className='locationBlock'>
                <div className='locationBlock_map'>
                    <Map
                        defaultState={{
                            center: [55.751574, 37.573856],
                            zoom: 15,
                            type: 'yandex#hybrid'
                        }}
                        width='100%'
                        height='100%'
                        onClick={(e) => {
                            setCoords(e.get('coords'))
                        }}
                    >
                        <FullscreenControl />
                        <SearchControl options={{ float: "right" }} />
                        <GeolocationControl options={{ float: "left" }} />
                        <TypeSelector options={{ float: 'right' }} />
                        {coords && <Placemark geometry={coords} />}
                    </Map>
                </div>
            </div>
            <Form.Item>
                <FormButton
                    title={'Создать'}
                    htmlType="submit"
                    style={{ marginTop: 16 }}
                />
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchCreateOrder: (data) => dispatch(fetchCreateOrder(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrderForm);