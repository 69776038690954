export const allOrdersTableWidth = [
    300,
    150,
    150,
    100,
    260,
    260,
    250,
    250,
    // null
]

export const allUserOrdersTableWidth = [
    380,
    200,
    // 100,
    150,
    200,
    null
]

export const allUserOffersTableWidth = [
    380,
    200,
    // 100,
    150,
    200,
    260,
    null
]

export const allContractorsTableWidth = [
    180,
    180,
    160,
    160,
    350,
    null
]

export const productsInOrderTableWidth = [
    300,
    120,
    120,
    260,
    // 100,
    100,
    null
]

export const distributionsInOrderTableWidth = [
    300,
    250,
    500,
    100,
    null
]

export const createDistributionTableWidth = [
    200,
    200,
    150,
    260,
    200,
    200,
    null
]


export const reviewInOrderTableWidth = [
    300,
    300,
    300,
    100,
    null
]

export const allVendorsTableWidth = [
    180,
    180,
    180,
    160,
    250,
    250,
    160,
    160,
    null
]

export const allUserFinanceTableWidth = [
    280,
    200,
    150,
    200,
    260,
]

export const allAdminFinanceTableWidth = [
    280,
    280,
    200,
    150,
    200,
    // 120,
    // 120,
    // 120,
    // 150,
    // 120,
    // 200,
    100,
    null
]

export const documentsInOrderTableWidth = [
    500,
    100,
    null
]