import { notification } from 'antd'
import downloading from 'assets/images/icons/downloading.svg'
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton'
import Table from 'components/shared/table/Table'
import InfoBlock from 'components/ui/infoBlock/InfoBlock'
import { ordersStatus } from 'data/statuses'
import { documentsInOrderTable, productsInOrderTable } from 'data/tableHeader'
import { documentsInOrderTableWidth, productsInOrderTableWidth } from 'data/tableWidth'
import { confirmNotification } from 'hooks/notifications'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { SET_VISIBLE_MODAL } from 'store/actionTypes'
import { fetchAcceptOrderById, fetchRejectOrderById } from 'store/actions/user/orders'

const MainInfo = ({ order_by_id_data, fetchAcceptOrderById, fetchRejectOrderById, setVisible }) => {
    const [api, contextHolder] = notification.useNotification();
    const { id } = useParams()
    const navigate = useNavigate()

    const createTableData = () => {
        return order_by_id_data.products.map(el => (
            [
                {
                    type: 'text',
                    content: el.material || '-'
                },
                {
                    type: 'text',
                    content: el.color || '-'
                },
                {
                    type: 'text',
                    content: el.infill || '-'
                },
                {
                    type: 'text',
                    content: el.printer || '-'
                },
                {
                    type: 'text',
                    content: el.quantity || '-'
                },
                // {
                //     type: 'text',
                //     content: el.price || '-'
                // },
                {
                    type: 'actions',
                    content: el.link_model !== '' && <a href={el.link_model} download target="_blank"><img src={downloading} alt="downloading" /></a>
                },
            ]
        ))
    }
    
    const createDocumentsTableData = () => {
        const docs = []
        order_by_id_data.code_files.forEach(el => (docs.push({
            type: 'code_files',
            data: el
        })))

        order_by_id_data.track_files.forEach(el => (docs.push({
            type: 'track_files',
            data: el
        })))

        return docs.map(el => (
            [
                {
                    type: 'text',
                    content: el.data.media.title || '-'
                },
                {
                    type: 'text',
                    content: el.type === 'code_files' ? 'Штрих-код' : 'Накладная'
                },
                {
                    type: 'actions',
                    content: <div className='tableIconsBlock'>
                        <a href={el.data.media.link} download target="_blank"><img src={downloading} alt="downloading" /></a>
                    </div>
                },
            ]
        ))
    }

    return (
        <>
            {contextHolder}
            <InfoBlock
                style={{ marginTop: 24, marginBottom: 24, gap: 10 }}
                className="infoBlock twoRow"
            >
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Цена</p>
                    <p className='p14_400 mainText p_mb16'>{(Math.round(order_by_id_data.commission_price * 10) / 10).toFixed(1) || '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Ожидаемая длительность исполнения, мин</p>
                    <p className='p14_400 mainText p_mb16'>{String(order_by_id_data.process_time) || '-'}</p>
                    <p className='p12_400 mainText p_mb8'>Дополнительная информация</p>
                    <p className='p14_400 mainText'>{order_by_id_data.additional_comment || '-'}</p>
                </div>
                <div style={{ width: '100%' }}>
                    {/* <p className='p12_400 mainText p_mb8'>Ожидаемая длительность исполнения, мин</p>
                    <p className='p14_400 mainText p_mb16'>{String(order_by_id_data.process_time) || '-'}</p> */}
                    <p className='p12_400 mainText p_mb8'>Статус</p>
                    <p className='p14_400 mainText p_mb16'><div className='statusOnTable p12_400' style={{ backgroundColor: ordersStatus[order_by_id_data.status].bg }}>{ordersStatus[order_by_id_data.status].title}</div></p>
                    <p className='p12_400 mainText p_mb8'>Трек-номер</p>
                    <p className='p14_400 mainText'>{order_by_id_data.track_num || '-'}</p>
                </div>
            </InfoBlock>
            {/* <InfoBlock
                style={{ marginTop: 24, marginBottom: 24 }}
                className="infoBlock twoRow"
            >
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Трек-номер</p>
                    <p className='p14_400 mainText'>{order_by_id_data.track_num || '-'}</p>
                </div>
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Файл накладной</p>
                    <p className='p14_400 mainText'>{order_by_id_data.track_file ? <a href={order_by_id_data.track_file.media.link} download target="_blank"><img src={downloading} alt="downloading" /></a> : '-'}</p>
                </div>
            </InfoBlock> */}
            {(order_by_id_data.code_files && order_by_id_data.track_files) &&
                <div className='p_mb16'>
                    <p className='p18_500 mainText p_mb8'>Документы</p>
                    <Table
                        header={documentsInOrderTable}
                        width={documentsInOrderTableWidth}
                        data={createDocumentsTableData()}
                    />
                </div>
            }
            <p className='p18_500 mainText p_mb8'>Заказ</p>
            <Table
                header={productsInOrderTable}
                width={productsInOrderTableWidth}
                data={createTableData()}
            />
            {order_by_id_data.status === 'VENDOR_DONE' &&
                <div className='twoButton' style={{ marginTop: 16 }}>
                    <div>
                        <SimpleButton
                            title={'Заказ отправлен на проверку'}
                            className="whiteButton"
                        />
                    </div>
                </div>
            }
            {(order_by_id_data.status === 'CREATED' || order_by_id_data.status === 'DISTIBUTING') &&
                <div className='twoButton' style={{ marginTop: 16 }}>
                    <div>
                        <SimpleButton
                            title={'Принять заказ'}
                            className="blueButton"
                            onClick={() => confirmNotification(api, async () => {
                                await fetchAcceptOrderById(id)
                                    .then(res => {
                                        if (res) navigate(-1)
                                    })
                                api.destroy()
                            }, 'свое согласие на выполнение заказа.')}
                        />
                    </div>
                    <div className='twoButton_item'>
                        <SimpleButton
                            title={'Отказаться от предложения'}
                            className='redButton'
                            onClick={() => confirmNotification(api, async () => {
                                // await fetchRejectOrderById(id)
                                //     .then(res => {
                                //         if (res) navigate(-1)
                                //     })
                                api.destroy()
                                setVisible('orderReject', { order_id: order_by_id_data.id })
                            }, 'отказ от предложения.')}
                        />
                    </div>
                </div>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        order_by_id_data: state.orderById.order_by_id_data,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchAcceptOrderById: (order_id) => dispatch(fetchAcceptOrderById(order_id)),
        fetchRejectOrderById: (order_id) => dispatch(fetchRejectOrderById(order_id)),
        setVisible: (modal_type, data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainInfo)