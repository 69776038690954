export const menuListUser = [
    {
        title: 'Профиль',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.10664 7.24671C6.03998 7.24004 5.95998 7.24004 5.88664 7.24671C4.29998 7.19337 3.03998 5.89337 3.03998 4.29337C3.03998 2.66004 4.35998 1.33337 5.99998 1.33337C7.63331 1.33337 8.95998 2.66004 8.95998 4.29337C8.95331 5.89337 7.69331 7.19337 6.10664 7.24671Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.94 2.66663C12.2333 2.66663 13.2733 3.71329 13.2733 4.99996C13.2733 6.25996 12.2733 7.28663 11.0267 7.33329C10.9733 7.32663 10.9133 7.32663 10.8533 7.33329" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.77335 9.70663C1.16002 10.7866 1.16002 12.5466 2.77335 13.62C4.60669 14.8466 7.61335 14.8466 9.44669 13.62C11.06 12.54 11.06 10.78 9.44669 9.70663C7.62002 8.48663 4.61335 8.48663 2.77335 9.70663Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.2267 13.3334C12.7067 13.2334 13.16 13.04 13.5334 12.7534C14.5734 11.9734 14.5734 10.6867 13.5334 9.90671C13.1667 9.62671 12.72 9.44004 12.2467 9.33337" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: '/user/my-profile'
    },
    {
        title: 'Заказы',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6666 6.66671V10C14.6666 13.3334 13.3333 14.6667 9.99998 14.6667H5.99998C2.66665 14.6667 1.33331 13.3334 1.33331 10V6.00004C1.33331 2.66671 2.66665 1.33337 5.99998 1.33337H9.33331" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6666 6.66671H12C9.99998 6.66671 9.33331 6.00004 9.33331 4.00004V1.33337L14.6666 6.66671Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.66669 8.66663H8.66669" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.66669 11.3334H7.33335" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: '/user/my-orders'
    },
    {
        title: 'Предложения',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.44 6.95996L13.7866 9.74663C13.2266 12.1533 12.12 13.1266 10.04 12.9266C9.70664 12.9 9.34664 12.84 8.95998 12.7466L7.83998 12.48C5.05998 11.82 4.19998 10.4466 4.85331 7.65997L5.50664 4.86663C5.63998 4.29996 5.79998 3.80663 5.99998 3.39996C6.77998 1.78663 8.10664 1.3533 10.3333 1.87996L11.4466 2.13996C14.24 2.7933 15.0933 4.1733 14.44 6.95996Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.04 12.9267C9.62665 13.2067 9.10665 13.44 8.47332 13.6467L7.41998 13.9934C4.77332 14.8467 3.37998 14.1334 2.51998 11.4867L1.66665 8.85336C0.813317 6.20669 1.51998 4.80669 4.16665 3.95336L5.21998 3.60669C5.49332 3.52002 5.75332 3.44669 5.99998 3.40002C5.79998 3.80669 5.63998 4.30002 5.50665 4.86669L4.85332 7.66002C4.19998 10.4467 5.05998 11.82 7.83998 12.48L8.95998 12.7467C9.34665 12.84 9.70665 12.9 10.04 12.9267Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.4267 5.68665L11.66 6.50665" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.77332 8.26672L9.70665 8.76006" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: '/user/my-offers'
    },
    {
        title: 'Финансы',
        icon: <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 5V3C15 2.46957 14.7893 1.96086 14.4142 1.58579C14.0391 1.21071 13.5304 1 13 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V9C1 9.53043 1.21071 10.0391 1.58579 10.4142C1.96086 10.7893 2.46957 11 3 11H5M7 15H17C17.5304 15 18.0391 14.7893 18.4142 14.4142C18.7893 14.0391 19 13.5304 19 13V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V13C5 13.5304 5.21071 14.0391 5.58579 14.4142C5.96086 14.7893 6.46957 15 7 15ZM14 10C14 10.5304 13.7893 11.0391 13.4142 11.4142C13.0391 11.7893 12.5304 12 12 12C11.4696 12 10.9609 11.7893 10.5858 11.4142C10.2107 11.0391 10 10.5304 10 10C10 9.46957 10.2107 8.96086 10.5858 8.58579C10.9609 8.21071 11.4696 8 12 8C12.5304 8 13.0391 8.21071 13.4142 8.58579C13.7893 8.96086 14 9.46957 14 10Z" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        ,
        path: '/user/my-finance'
    },
]

export const menuListAdmin = [
    {
        title: 'Исполнители',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.10664 7.24671C6.03998 7.24004 5.95998 7.24004 5.88664 7.24671C4.29998 7.19337 3.03998 5.89337 3.03998 4.29337C3.03998 2.66004 4.35998 1.33337 5.99998 1.33337C7.63331 1.33337 8.95998 2.66004 8.95998 4.29337C8.95331 5.89337 7.69331 7.19337 6.10664 7.24671Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.94 2.66663C12.2333 2.66663 13.2733 3.71329 13.2733 4.99996C13.2733 6.25996 12.2733 7.28663 11.0267 7.33329C10.9733 7.32663 10.9133 7.32663 10.8533 7.33329" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.77335 9.70663C1.16002 10.7866 1.16002 12.5466 2.77335 13.62C4.60669 14.8466 7.61335 14.8466 9.44669 13.62C11.06 12.54 11.06 10.78 9.44669 9.70663C7.62002 8.48663 4.61335 8.48663 2.77335 9.70663Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.2267 13.3334C12.7067 13.2334 13.16 13.04 13.5334 12.7534C14.5734 11.9734 14.5734 10.6867 13.5334 9.90671C13.1667 9.62671 12.72 9.44004 12.2467 9.33337" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: 'venders'
    },
    {
        title: 'Заказы',
        icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6666 6.66671V10C14.6666 13.3334 13.3333 14.6667 9.99998 14.6667H5.99998C2.66665 14.6667 1.33331 13.3334 1.33331 10V6.00004C1.33331 2.66671 2.66665 1.33337 5.99998 1.33337H9.33331" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6666 6.66671H12C9.99998 6.66671 9.33331 6.00004 9.33331 4.00004V1.33337L14.6666 6.66671Z" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.66669 8.66663H8.66669" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.66669 11.3334H7.33335" stroke="#A8A8A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        path: 'orders'
    },
    {
        title: 'Финансы',
        icon: <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 5V3C15 2.46957 14.7893 1.96086 14.4142 1.58579C14.0391 1.21071 13.5304 1 13 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V9C1 9.53043 1.21071 10.0391 1.58579 10.4142C1.96086 10.7893 2.46957 11 3 11H5M7 15H17C17.5304 15 18.0391 14.7893 18.4142 14.4142C18.7893 14.0391 19 13.5304 19 13V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V13C5 13.5304 5.21071 14.0391 5.58579 14.4142C5.96086 14.7893 6.46957 15 7 15ZM14 10C14 10.5304 13.7893 11.0391 13.4142 11.4142C13.0391 11.7893 12.5304 12 12 12C11.4696 12 10.9609 11.7893 10.5858 11.4142C10.2107 11.0391 10 10.5304 10 10C10 9.46957 10.2107 8.96086 10.5858 8.58579C10.9609 8.21071 11.4696 8 12 8C12.5304 8 13.0391 8.21071 13.4142 8.58579C13.7893 8.96086 14 9.46957 14 10Z" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>,
        path: 'finance'
    },
]
