import OrderRejectForm from "components/components/forms/modal/orderReject/OrderRejectForm"

const OrderReject = () => {
    return (
        <div>
            <p className="mainText p24_600 p_mb8" style={{ textAlign: 'center', width: '30vw' }}>Отказаться от предложения</p>
            <p className="subtext p_mb16" style={{ textAlign: 'center' }}>Перед отказом от предложения, напиши причину</p>
            <OrderRejectForm />
        </div>
    )
}

export default OrderReject