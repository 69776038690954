import { ApiUrl, instance } from '../apiEnv';

export const api_operations = {

    async GetOperations(page, size, params) {
        return await instance.get(`${ApiUrl}operations?page=${page}&size=${size}&${params}`)
    },

    async CompleteOperation(operation_id) {
        return await instance.put(`${ApiUrl}operations/${operation_id}/complite`)
    },

    async RejectOperation(operation_id) {
        return await instance.put(`${ApiUrl}operations/${operation_id}/reject`)
    },
}