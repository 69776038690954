import { Form, Input } from 'antd';
import InfoBlock from 'components/ui/infoBlock/InfoBlock';
import { connect } from 'react-redux';
import { fetchUpdateMyData } from 'store/actions/user/user';

function EditMainInfoForm({
    form,
    initial,
    user_data,
    coords,
    fetchUpdateMyData
}) {

    const onFinish = async (values) => {
        if (coords) {
            values.latitude = coords[0]
            values.longitude = coords[1]
        }
        fetchUpdateMyData({ ...user_data, ...values })
    };

    return (
        <InfoBlock
            style={{ marginTop: 24 }}
        >
            <Form
                layout="vertical"
                requiredMark={false}
                initialValues={initial}
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                className="modalForm"
            >
                <Form.Item
                    label={'Название организации'}
                    name="organization_name"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите название организации' />
                </Form.Item>
                <Form.Item
                    label={'Юридический адрес'}
                    name="legal_address"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите юридический адрес' />
                </Form.Item>
                <Form.Item
                    label={'ИНН'}
                    name="inn"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле, которое содержит только цифры',
                            pattern: new RegExp('^[0-9]*$')
                        },
                    ]}
                >
                    <Input placeholder='Введите ИНН' />
                </Form.Item>
                <Form.Item
                    label={'КПП'}
                    name="kpp"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите КПП' />
                </Form.Item>
                <Form.Item
                    label={'ОГРН'}
                    name="ogrnip"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле, которое содержит только цифры',
                            pattern: new RegExp('^[0-9]*$')
                        },
                    ]}
                >
                    <Input placeholder='Введите ОГРН' />
                </Form.Item>
                <Form.Item
                    label={'Название банка'}
                    name="bank_name"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите название банка' />
                </Form.Item>
                <Form.Item
                    label={'Расчетный счет'}
                    name="bank_account"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите Расчетный счет' />
                </Form.Item>
                <Form.Item
                    label={'БИК банк'}
                    name="bic"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле, которое содержит только цифры',
                            pattern: new RegExp('^[0-9]*$')
                        },
                    ]}
                >
                    <Input placeholder='Введите БИК банк' />
                </Form.Item>
                <Form.Item
                    label={'Корреспондентский счет банка'}
                    name="correspondent_account"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле, которое содержит только цифры',
                            pattern: new RegExp('^[0-9]*$')
                        },
                    ]}
                >
                    <Input placeholder='Введите Корреспондентский счет банка' />
                </Form.Item>
            </Form>
        </InfoBlock>
    );
}

function mapStateToProps(state) {
    return {
        user_data: state.user.user_data,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchUpdateMyData: (data) => dispatch(fetchUpdateMyData(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMainInfoForm);