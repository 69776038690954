import OrderDistributeForm from "components/components/forms/modal/orderDistribute/OrderDistributeForm"

const OrderDistribute = () => {
    return (
        <div style={{maxWidth: '30vw'}}>
            <p className="mainText p24_600 p_mb8" style={{ textAlign: 'center', width: '30vw' }}>Сформировать очередь</p>
            <p className="subtext p_mb16" style={{ textAlign: 'center' }}>Вы может выбрать исполнителей, которым не хотите предлагать заказ</p>
            <OrderDistributeForm />
        </div>
    )
}

export default OrderDistribute