export const adminOrdersBreadcrumb = [
    {
        title: 'Заказы',
        to: -1
    }
]

export const userOrdersBreadcrumb = [
    {
        title: 'Заказы',
        to: -1
    }
]

export const adminVendersBreadcrumb = [
    {
        title: 'Исполнители',
        to: -1
    }
]