import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton'
import Table from 'components/shared/table/Table'
import { distributionsStatus } from 'data/statuses'
import { reviewInOrderTable } from 'data/tableHeader'
import { reviewInOrderTableWidth } from 'data/tableWidth'
import { connect } from 'react-redux'
import { SET_VISIBLE_MODAL } from 'store/actionTypes'

const Verification = ({ order_by_id_data, setVisible }) => {

    const createReviewTableData = () => {
        return order_by_id_data.verifications.map(el => (
            [
                {
                    type: 'text',
                    content: el.vendor_id || '-'
                },
                {
                    type: 'wrap_text',
                    content: el.desc || '-'
                },
                {
                    type: 'wrap_text',
                    content: el.vendor_description || '-'
                },
                {
                    type: 'text',
                    content: <div className='statusOnTable p12_400' style={{ backgroundColor: distributionsStatus[el.status].bg }}>{distributionsStatus[el.status].title}</div>
                },
                {
                    type: 'actions',
                    content: el.status === 'WAITING' ? <SimpleButton title={'Проверить'} className="whiteButton" onClick={() => setVisible({ data: el })} /> : <div style={{ width: 114 }} />
                },
            ]
        ))
    }

    return (
        <>
            <p className='p18_500 mainText p_mb8'>Проверка</p>
            <Table
                header={reviewInOrderTable}
                width={reviewInOrderTableWidth}
                data={createReviewTableData()}
            />
        </>
    )
}

function mapStateToProps(state) {
    return {
        order_by_id_data: state.adminOrderById.order_by_id_data,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setVisible: (data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'verificationOrder', data }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Verification)