import { Form, Input, Upload } from "antd";
import uploadIcon from 'assets/images/icons/upload.svg';
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import UploadCard from "components/shared/cards/uploadCard/UploadCard";
import { useState } from "react";
import { connect } from 'react-redux';
import { CLOSE_MODAL, SET_VISIBLE_MODAL } from "store/actionTypes";
import { fetchAcceptVerification } from "store/actions/admin/orders";

const propsDocsUpload = {
    action: '',
    accept: ".jpg, .jpeg, .pdf",
    listType: "text",
    multiple: true,
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

const AcceptVerificationOrderForm = ({ data, setVisible, goBackModal, fetchAcceptVerification }) => {
    const [fileDocsList, setFileDocsList] = useState([])
    const [codeFileDocsList, setCodeFileDocsList] = useState([])
    const [errorStatusFirst, setErrorStatusFirst] = useState({})

    const onFinish = ({ track_num }) => {
        let requestBody = fileDocsList.length ? new FormData() : null;
        let codeFilesBody = codeFileDocsList.length ? new FormData() : null;
        for (let i = 0; i < codeFileDocsList.length; i++) {
            let fileData = codeFileDocsList[i].originFileObj
            delete fileData.uid
            codeFilesBody.append('code_files', fileData);
        }
        // if (fileDocsList.length) {
            for (let i = 0; i < fileDocsList.length; i++) {
                let fileData = fileDocsList[i].originFileObj
                delete fileData.uid
                requestBody.append('track_files', fileData);
            }
            fetchAcceptVerification(data.data.order_id, data.data.id, track_num, requestBody, codeFilesBody)
            setVisible()
        // } else {
        //     setErrorStatusFirst({
        //         validateStatus: "error",
        //         help: "Отсутствует файл"
        //     })
        // }
    };

    const normFile = (e, type) => {
        if(type === 'track_files') {
            setFileDocsList(e.fileList)
        } else {
            setCodeFileDocsList(e.fileList)
        }

        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const deleteFile = (index, type) => {
        if(type === 'track_files') {
            const files = [...fileDocsList];
            files.splice(index, 1);
            setFileDocsList(files);
        } else {
            const files = [...codeFileDocsList];
            files.splice(index, 1);
            setCodeFileDocsList(files);
        }

    }

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <Form.Item
                label={'Трек-номер отправления'}
                name="track_num"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Укажите трек-номер отправления' />
            </Form.Item>
            <div style={{ marginTop: 24 }}>
                <p className="p14_400 p_mb8">Файл накладной</p>
                <div className={`${fileDocsList.length === 0 ? '' : 'squareUploadBlock'}`}>
                    <div className='squareUploadBlock'>
                        {fileDocsList.map((el, index) => (
                            <UploadCard key={`UploadCard_${index}`} title={el.name || el.media.title} onClick={() => deleteFile(index, 'track_files')} />
                        ))}
                        {!fileDocsList.length &&
                            <Form.Item
                                name="filePhotoList"
                                valuePropName="filePhotoList"
                                getValueFromEvent={(e) => normFile(e, 'track_files')}
                                className={`${fileDocsList.length === 0 ? '' : 'squareUpload'}`}
                                style={{ width: '100%' }}
                                rules={[
                                    {
                                        required: false,
                                        message: 'Это обязательное поле',
                                    },
                                ]}
                                {...errorStatusFirst}
                            >
                                {fileDocsList.length === 0
                                    ?
                                    <Upload.Dragger
                                        name="fileDocsList"
                                        action="/upload.do"
                                        itemRender={null}
                                        customRequest={dummyRequest}
                                        {...propsDocsUpload}
                                    >
                                        <div style={{ width: '100%', margin: '100px auto' }}>
                                            <p className="subtext upload-text">Перетащите сюда, чтобы загрузить файл</p>
                                            <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                        </div>
                                    </Upload.Dragger>
                                    :
                                    <Upload.Dragger
                                        name="fileDocsList"
                                        action="/upload.do"
                                        itemRender={null}
                                        customRequest={dummyRequest}
                                        {...propsDocsUpload}
                                        fileList={fileDocsList}
                                    >
                                        <img src={uploadIcon} alt="uploadIcon" />
                                    </Upload.Dragger>
                                }
                            </Form.Item>
                        }
                    </div>
                </div>
            </div>
            <div>
                <p className="p14_400 p_mb8">Штрих-код</p>
                <div className={`${codeFileDocsList.length === 0 ? '' : 'squareUploadBlock'}`}>
                    <div className='squareUploadBlock'>
                        {codeFileDocsList.map((el, index) => (
                            <UploadCard key={`UploadCard_${index}`} title={el.name || el.media.title} onClick={() => deleteFile(index, 'code_files')} />
                        ))}
                        {!codeFileDocsList.length &&
                            <Form.Item
                                name="codeFileDocsList"
                                valuePropName="codeFileDocsList"
                                getValueFromEvent={(e) => normFile(e, 'code_files')}
                                className={`${codeFileDocsList.length === 0 ? '' : 'squareUpload'}`}
                                style={{ width: '100%' }}
                                rules={[
                                    {
                                        required: false,
                                        message: 'Это обязательное поле',
                                    },
                                ]}
                            >
                                {codeFileDocsList.length === 0
                                    ?
                                    <Upload.Dragger
                                        name="codeFileDocsList"
                                        action="/upload.do"
                                        itemRender={null}
                                        customRequest={dummyRequest}
                                        {...propsDocsUpload}
                                    >
                                        <div style={{ width: '100%', margin: '100px auto' }}>
                                            <p className="subtext upload-text">Перетащите сюда, чтобы загрузить файл</p>
                                            <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                        </div>
                                    </Upload.Dragger>
                                    :
                                    <Upload.Dragger
                                        name="codeFileDocsList"
                                        action="/upload.do"
                                        itemRender={null}
                                        customRequest={dummyRequest}
                                        {...propsDocsUpload}
                                        fileList={codeFileDocsList}
                                    >
                                        <img src={uploadIcon} alt="uploadIcon" />
                                    </Upload.Dragger>
                                }
                            </Form.Item>
                        }
                    </div>
                </div>
            </div>
            <Form.Item
                style={{ marginBottom: 0 }}
            >
                <div>
                    <SimpleButton
                        title={'Отмена'}
                        className="activeText p14_500 whiteButton"
                        style={{
                            marginRight: 16
                        }}
                        onClick={() => goBackModal('verificationOrder', data)}
                    />
                    <SimpleButton
                        title={'Подтвердить'}
                        className="p14_500 blueButton"
                        htmltype="submit"
                    />
                </div>
            </Form.Item>
        </Form>
    )
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        goBackModal: (modal_type, data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
        fetchAcceptVerification: (order_id, verification_id, track_num, track_files, code_files) => dispatch(fetchAcceptVerification(order_id, verification_id, track_num, track_files, code_files)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptVerificationOrderForm)