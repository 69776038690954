import CreateOrderForm from 'components/components/forms/createOrder/CreateOrder'
import Breadcrumb from "components/shared/breadcrumb/Breadcrumb"
import TextHeading from "components/ui/teaxtHeading/TextHeading"
import { adminOrdersBreadcrumb } from 'data/breadcrumb'

const CreateOrder = () => {
    return (
        <div className='contentBlock'>
            <Breadcrumb list={adminOrdersBreadcrumb} activeLink={'Создание заказа'} />
            <TextHeading text={`Создание заказа`} />
            <CreateOrderForm />
        </div>
    )
}

export default CreateOrder