export const adminOrdersFilter = [
    {
        title: 'Статус',
        param: 'statuses',
        childrens: [
            {
                title: 'Отправить на распределение',
                param: 'CREATED'
            },
            {
                title: 'На распределении',
                param: 'DISTIBUTING'
            },
            {
                title: 'В процессе',
                param: 'IN_PROGRESS'
            },
            {
                title: 'Сдан на проверку',
                param: 'VENDOR_DONE'
            },
            {
                title: 'Ожидает отгрузки',
                param: 'IN_SHIPPING'
            },
            {
                title: 'Выполнено',
                param: 'COMPLITED'
            },
            {
                title: 'Остановлен',
                param: 'STOPPED'
            },
            {
                title: 'Деактивирован',
                param: 'REJECTED'
            },
            {
                title: 'На доработке',
                param: 'ON_REVISION'
            }
        ]
    }
]

export const userOrdersFilter = [
    {
        title: 'Статус',
        param: 'statuses',
        childrens: [
            {
                title: 'В процессе',
                param: 'IN_PROGRESS'
            },
            {
                title: 'Сдан на проверку',
                param: 'VENDOR_DONE'
            },
            {
                title: 'Выполнено',
                param: 'COMPLITED'
            },
            {
                title: 'Деактивирован',
                param: 'REJECTED'
            },
            {
                title: 'На доработке',
                param: 'ON_REVISION'
            }
        ]
    }
]

export const adminContractorsFilter = [
    {
        title: 'Статус',
        param: 'status',
        childrens: [
            {
                title: 'Активна',
                param: 'open'
            },
            {
                title: 'Завершена',
                param: 'close'
            },
        ]
    }
] 

export const adminFinanceFilter = [
    {
        title: 'Статус',
        param: 'statuses',
        childrens: [
            {
                title: 'В ожидании',
                param: 'WAITING'
            },
            {
                title: 'Выполнено',
                param: 'SUCCEEDED'
            },
            // {
            //     title: 'Отменено',
            //     param: 'CANCELLED'
            // },
            {
                title: 'Отклонено',
                param: 'REJECTED'
            },
        ]
    }
]