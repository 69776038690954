import EmailLogin from "components/components/forms/authentication/login/emailLogin/EmailLogin";
import AuthenticationBlock from "components/ui/authenticationBlock/AuthenticationBlock";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import { Link } from 'react-router-dom';

import '../style.css';

function Login() {
    return (
        <AuthenticationBlock>
            <TextHeading text={'Вход'} style={{ textAlign: 'center' }} />
            <EmailLogin />
            <Link to={'/passwordRecovery'} className="p14_400 activeText">Забыли пароль?</Link>
        </AuthenticationBlock>
    );
}

export default Login;