import CreateVenderForm from 'components/components/forms/createVender/CreateVenderForm'
import Breadcrumb from "components/shared/breadcrumb/Breadcrumb"
import TextHeading from "components/ui/teaxtHeading/TextHeading"
import { adminVendersBreadcrumb } from 'data/breadcrumb'

const CreateVender = () => {
    return (
        <div className='contentBlock'>
            <Breadcrumb list={adminVendersBreadcrumb} activeLink={'Создание исполнителя'} />
            <TextHeading text={`Создание исполнителя`} />
            <CreateVenderForm />
        </div>
    )
}

export default CreateVender