import { Form, Select } from 'antd';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import { useState } from 'react';
import { connect } from 'react-redux';
import { CLOSE_MODAL } from 'store/actionTypes';
import { fetchOrderDistributeWithData } from 'store/actions/admin/orders';


function OrderDistributeForm({ reject, defData, data, venders, setVisible, fetchOrderDistributeWithData }) {
    const [fields, setFields] = useState([
        {
            name: 'blocked_vender_id',
            value: []
        }
    ]);

    const onFinish = async ({ blocked_vender_id }) => {
        await fetchOrderDistributeWithData(data.order_id, blocked_vender_id)
        setVisible()
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <Form.Item
                label='Исполнитель/исполнители на блокировку'
                name="blocked_vender_id"
                style={{ width: '100%' }}
            >
                <Select
                    placeholder="Выберите исполнителей"
                    style={{ width: '100%' }}
                    mode="multiple"
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={venders.map(el => ({
                        value: el.id,
                        label: el.first_name + ' ' + el.last_name,
                    }))}
                />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: 0 }}
            >
                <div>
                    <SimpleButton
                        title={'Отмена'}
                        className="activeText p14_500 whiteButton"
                        style={{
                            marginRight: 16
                        }}
                        onClick={setVisible}
                    />
                    <SimpleButton
                        title={'Сформировать очередь'}
                        className="p14_500 blueButton"
                        htmltype="submit"
                    />
                </div>
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
        venders: state.venders.venders,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        fetchOrderDistributeWithData: (order_id, data) => dispatch(fetchOrderDistributeWithData(order_id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDistributeForm);