import {
    ADMIN_CLEAR_USER_BY_ID_DATA,
    ADMIN_FETCH_USER_BY_ID_DATA_START,
    ADMIN_FETCH_USER_BY_ID_DATA_SUCCESS,
} from '../../actionTypes'

const initialState = {
    user_by_id_data: null,
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case ADMIN_FETCH_USER_BY_ID_DATA_START:
            return {
                ...state,
            }
        case ADMIN_FETCH_USER_BY_ID_DATA_SUCCESS:
            return {
                ...state,
                user_by_id_data: action.data.user_by_id_data,
            }
        case ADMIN_CLEAR_USER_BY_ID_DATA:
            return {
                user_by_id_data: null,
            }
        default: return state
    }
}