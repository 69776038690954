import { notification } from "antd";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";

import ban from 'assets/images/icons/ban.svg';
import info_circle_red from 'assets/images/icons/info_circle_red.svg';
import warning from 'assets/images/icons/warning.svg';

export const successNotification = (text, description) => {
    notification.open({
        message: <p className="activeText p14_500">{text}</p>,
        description: <div className="subtext p14_400">
            <p>{description}</p>
        </div>,
    });
}

export const errorNotification = (text, description) => {
    notification.open({
        message: <p className="errorColor p14_500">{text}</p>,
        description: <div className="subtext p14_400">
            <p>{description}</p>
            <p>Повторите попытку</p>
        </div>,
        icon: (
            <img src={ban} />
        )
    });
}

export const errorNotificationWithButton = (api, text, description, buttonTitle, confirm) => {
    const btn = (
        <div className='deleteNotificationButtons'>
            <SimpleButton
                title={buttonTitle}
                className="p14_400 errorColor"
                style={{
                    padding: '7px 16px',
                    border: '1px solid #F44336',
                    borderRadius: '4px',
                    backgroundColor: 'white'
                }}
                onClick={() => {
                    confirm()
                    api.destroy()
                }}
            />
        </div>
    )
    api.open({
        message: <p className="errorColor p14_500">{text}</p>,
        description: <div className="subtext p14_400">
            <p>{description}</p>
            <p>Повторите попытку</p>
        </div>,
        btn,
        icon: (
            <img src={ban} />
        )
    });
}

export const warningNotification = (api, confirm, description) => {
    const btn = (
        <div className='deleteNotificationButtons'>
            <SimpleButton
                title={'Отмена'}
                className="p14_400 mainText"
                style={{
                    backgroundColor: 'white',
                    marginRight: 16
                }}
                onClick={() => api.destroy()}
            />
            <SimpleButton
                title={'Подтвердить'}
                className="p14_400 errorColor"
                style={{
                    padding: '7px 16px',
                    border: '1px solid #F44336',
                    borderRadius: '4px',
                    backgroundColor: 'white'
                }}
                onClick={confirm}
            />
        </div>
    )
    api.open({
        message: <p className="errorColor p14_500">Предупреждение</p>,
        description:
            <div className="subtext p14_400">
                <p>Подтвердите {description}</p>
                {/* <p>{description}</p> */}
            </div>,
        btn,
        icon: (
            <img src={info_circle_red} />
        )
    })
}

export const warningNotificationWithoutConfirmation = (description) => {
    notification.open({
        message: <p className="warnintText p14_500">Предупреждение</p>,
        description: <div className="subtext p14_400">
            {description}
        </div>,
        icon: (
            <img src={warning} />
        )
    })
}

export const confirmNotification = (api, confirm, description) => {
    const btn = (
        <div className='deleteNotificationButtons'>
            <SimpleButton
                title={'Отмена'}
                className="p14_400 mainText"
                style={{
                    backgroundColor: 'white',
                    marginRight: 16
                }}
                onClick={() => api.destroy()}
            />
            <SimpleButton
                title={'Подтвердить'}
                className="p14_400 warnintText whiteButton"
                onClick={confirm}
            />
        </div>
    )
    api.open({
        message: <p className="warnintText p14_500">Предупреждение</p>,
        description:
            <div className="subtext p14_400">
                <p>Подтвердите</p>
                <p>{description}</p>
            </div>,
        btn,
        // icon: (
        //     <img src={info_circle_red} />
        // )
    })
}