export const adminListOrderStatuses = [
    {
        status: 'CREATED',
        title: 'Отправить на распределение',
        bg: '#FFC107'
    },
    {
        status: 'DISTIBUTING',
        title: 'На распределении',
        bg: '#3949AB'
    },
    {
        status: 'IN_PROGRESS',
        title: 'В процессе',
        bg: '#43A047'
    },
    {
        status: 'VENDOR_DONE',
        title: 'Сдан на проверку',
        bg: '#43A047'
    },
    {
        status: 'IN_SHIPPING',
        title: 'Ожидает отгрузки',
        bg: '#FFC107'
    },
    {
        status: 'COMPLITED',
        title: 'Выполнено',
        bg: '#43A047'
    },
    {
        status: 'STOPPED',
        title: 'Остановлен',
        bg: '#D32F2F'
    },
    {
        status: 'ON_REVISION',
        title: 'На доработке',
        bg: '#D32F2F'
    },
    {
        status: 'REJECTED',
        title: 'Деактивирован',
        bg: '#D32F2F'
    },
]

export const adminListDistributionStatuses = [
    {
        status: 'ACCEPTED',
        title: 'Принято',
        bg: '#43A047'
    },
    {
        status: 'REJECTED',
        title: 'Отклонен',
        bg: '#D32F2F'
    },
    {
        status: 'CANCELED',
        title: 'Нет ответа',
        bg: '#EF6C00'
    },
]

export const deleteDistributeList = ['ACCEPTED', 'ON_REVISION']