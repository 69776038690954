import NoAuthLayout from "components/layout/noAuth/NoAuthLayout";

import Login from "views/authentication/login/Login";
import NewPassword from "views/authentication/newPassword/NewPassword";
import PasswordRecovery from "views/authentication/passwordRecovery/PasswordRecovery";

export const noAuthRoutings = [
    {
        path: '/',
        element: <NoAuthLayout />,
        navigate: '/',
        children: [
            {
                path: 'login',
                index: true,
                element: <Login />
            },
            {
                path: 'passwordRecovery',
                index: false,
                element: <PasswordRecovery />
            },
            {
                path: 'reset_password',
                index: false,
                element: <NewPassword />
            },
        ]
    },
]
