export const distributionsStatus = {
    IN_QUEUE: {
        title: 'В очереди',
        bg: '#3949AB'
    },
    WAITING: {
        title: 'Ожидание',
        bg: '#FFC107'
    },
    CANCELED: {
        title: 'Нет ответа',
        bg: '#EF6C00'
    },
    ACCEPTED: {
        title: 'Принято',
        bg: '#43A047'
    },
    REJECTED: {
        title: 'Отклонен',
        bg: '#D32F2F'
    },
    ON_REVISION: {
        title: 'На доработке',
        bg: '#D32F2F'
    }
}

export const ordersStatus = {
    CREATED: {
        title: 'Отправить на распределение',
        bg: '#FFC107'
    },
    DISTRIBUTING: {
        title: 'На распределении',
        bg: '#3949AB'
    },
    DISTIBUTING: {
        title: 'На распределении',
        bg: '#3949AB'
    },
    IN_PROGRESS: {
        title: 'В процессе',
        bg: '#43A047'
    },
    VENDOR_DONE: {
        title: 'Сдан на проверку',
        bg: '#43A047'
    },
    COMPLITED: {
        title: 'Выполнено',
        bg: '#43A047'
    },
    REJECTED: {
        title: 'Деактивирован',
        bg: '#D32F2F'
    },
    ON_REVISION: {
        title: 'На доработке',
        bg: '#D32F2F'
    },
    STOPPED: {
        title: 'Остановлен',
        bg: '#D32F2F'
    },
    IN_SHIPPING: {
        title: 'Ожидает отгрузки',
        bg: '#FFC107'
    },
}

export const operationStatus = {
    WAITING: {
        title: 'В ожидании',
        bg: '#FFC107'
    },
    SUCCEEDED: {
        title: 'Выполнено',
        bg: '#43A047'
    },
    CANCELLED: {
        title: 'Отменено',
        bg: '#D32F2F'
    },
    REJECTED: {
        title: 'Отклонено',
        bg: '#D32F2F'
    },
}