import { Form, InputNumber } from 'antd';
import editPen from 'assets/images/icons/edit_pen.svg';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchUpdateOrder } from 'store/actions/admin/orders';

function UpdateOrderPriceForm({
    // form,
    initial,
    onSave,
    name,
    max = null,
    fetchUpdateOrder
}) {
    const { id } = useParams()
    const [form] = Form.useForm()

    const onFinish = async (values) => {
        fetchUpdateOrder(id, values)
        onSave()
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            initialValues={initial}
            form={form}
            style={{ display: 'flex', alignItems: 'center', gap: 10, marginBottom: 16 }}
        >
            <Form.Item
                name={name}
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
                style={{
                    marginBottom: 0
                }}
            >
                <InputNumber placeholder='Введите стоимость' max={max} />
            </Form.Item>
            <img
                src={editPen}
                alt='editPen'
                width={15}
                style={{ cursor: 'pointer' }}
                onClick={() => { form.submit() }}
            />
        </Form>
    );
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchUpdateOrder: (order_id, data) => dispatch(fetchUpdateOrder(order_id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOrderPriceForm);