import { Form, Radio, Upload } from 'antd';
import uploadIcon from 'assets/images/icons/upload.svg';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import UploadCard from 'components/shared/cards/uploadCard/UploadCard';
import { errorNotification } from 'hooks/notifications';
import { useState } from 'react';
import { connect } from 'react-redux';
import { CLOSE_MODAL } from 'store/actionTypes';
import { fetchUploadCodeFiles, fetchUploadTrackFiles } from 'store/actions/admin/orders';

const propsDocsUpload = {
    action: '',
    accept: ".png, .jpg, .jpeg, .pdf, .docx, .doc",
    listType: "text",
    multiple: true,
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

function AddDocumentsOnOrderForm({ reject, defData, data, setVisible, fetchUploadTrackFiles, fetchUploadCodeFiles }) {
    const [fileDocsList, setFileDocsList] = useState([])
    const [fields, setFields] = useState([

    ]);

    const onFinish = ({ document_type }) => {
        let requestBody = new FormData();
        if (!fileDocsList.length) {
            errorNotification('Отсутствуют файлы!', 'Для продолжения необходимо прикрепить файлы')
            return
        }
        for (let i = 0; i < fileDocsList.length; i++) {
            let filedata = fileDocsList[i].originFileObj
            filedata.title = filedata.name 
            delete filedata.uid
            if (document_type === 'track_files') {
                requestBody.append('track_files', filedata);
            } else {
                requestBody.append('code_files', filedata);
            }
        }
        if (document_type === 'track_files') {
            fetchUploadTrackFiles(data.id, requestBody)
        } else {
            fetchUploadCodeFiles(data.id, requestBody)
        }
        setVisible()
    };

    const normFile = (e, type) => {
        setFileDocsList(e.fileList)

        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const deleteFile = (index, type) => {
        const files = [...fileDocsList];
        files.splice(index, 1);
        setFileDocsList(files);

    }

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <Form.Item
                name="document_type"
                label="Тип документа"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Radio.Group>
                    <Radio value="track_files">Накладная</Radio>
                    <Radio value="code_files">Штрих-код</Radio>
                </Radio.Group>
            </Form.Item>
            <div style={{ marginTop: 24 }}>
                <div className={`${fileDocsList.length === 0 ? '' : 'squareUploadBlock'}`}>
                    <div className='squareUploadBlock'>
                        {fileDocsList.map((el, index) => (
                            <UploadCard key={`UploadCard_${index}`} title={el.name || el.media.title} onClick={() => deleteFile(index, 'fileDocsList')} />
                        ))}
                        <Form.Item
                            name="filePhotoList"
                            valuePropName="filePhotoList"
                            getValueFromEvent={(e) => normFile(e, 'fileDocsList')}
                            className={`${fileDocsList.length === 0 ? '' : 'squareUpload'}`}
                            style={{ width: '100%' }}
                        >
                            {fileDocsList.length === 0
                                ?
                                <Upload.Dragger
                                    name="fileDocsList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsDocsUpload}
                                >
                                    <div style={{ width: '100%', margin: '100px auto' }}>
                                        <p className="subtext upload-text">Перетащите сюда, чтобы загрузить файл</p>
                                        <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                    </div>
                                </Upload.Dragger>
                                :
                                <Upload.Dragger
                                    name="fileDocsList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsDocsUpload}
                                    fileList={fileDocsList}
                                >
                                    <img src={uploadIcon} alt="uploadIcon" />
                                </Upload.Dragger>
                            }
                        </Form.Item>
                    </div>
                </div>
            </div>
            <Form.Item
                style={{ marginBottom: 0 }}
            >
                <div>
                    <SimpleButton
                        title={'Отмена'}
                        className="activeText p14_500 whiteButton"
                        style={{
                            marginRight: 16
                        }}
                        onClick={setVisible}
                    />
                    <SimpleButton
                        title={'Сохранить'}
                        className="p14_500 blueButton"
                        htmltype="submit"
                    />
                </div>
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        fetchUploadTrackFiles: (order_id, data) => dispatch(fetchUploadTrackFiles(order_id, data)),
        fetchUploadCodeFiles: (order_id, data) => dispatch(fetchUploadCodeFiles(order_id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDocumentsOnOrderForm);