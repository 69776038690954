import { combineReducers } from 'redux';

/*general data */
import materials from './reducers/materials';
import modal from './reducers/modal';
import printers from './reducers/printers';
import roles from './reducers/roles';
import userEnv from './reducers/userEnv';

/*admin data */
import adminOperations from './reducers/admin/allOperations';
import adminOrders from './reducers/admin/allOrders';
import venders from './reducers/admin/allVenders';
import adminOrderById from './reducers/admin/orderById';
import adminUserById from './reducers/admin/userById';

/*user data */
import orders from './reducers/user/allOrders';
import operations from './reducers/user/operations';
import orderAccepted from './reducers/user/orderAccepted';
import orderById from './reducers/user/orderById';
import user from './reducers/user/user';

export default combineReducers({
    /*general data */
    userEnv,
    printers,
    modal,
    materials,
    roles,

    /*admin data */
    adminOrders,
    adminOrderById,
    venders,
    adminUserById,
    adminOperations,

    /*user data */
    user,
    orders,
    orderById,
    orderAccepted,
    operations,
})