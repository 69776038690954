import {
    FETCH_ALL_MATERIALS_START,
    FETCH_ALL_MATERIALS_SUCCESS,
} from '../actionTypes'

const initialState = {
    materials: [],
    pages: 0,
    page: 0,
    size: 0,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_MATERIALS_START:
            return {
                ...state, loading: true
            }
        case FETCH_ALL_MATERIALS_SUCCESS:
            return {
                materials: action.data.data.items,
                pages: action.data.data.pages,
                page: action.data.data.page,
                size: action.data.data.size,
                loading: false
            }
        default: return state
    }
}