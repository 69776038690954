import DrawerMenu from "components/shared/drawerMenu/DrawerManu";
import { menuListAdmin } from "data/routings";
import { useEffect } from "react";
import { connect } from 'react-redux';
import { Outlet } from "react-router-dom";
import { fetchMaterials } from "store/actions/materials";
import { fetchPrinters } from "store/actions/printers";
import Header from "./header/Header";

import { fetchRoles } from "store/actions/roles";
import '../user/style.css';
import './style.css';

function AdminLayout({
    access_token,
    fetchMaterials,
    fetchPrinters,
    fetchRoles,
}) {

    useEffect(() => {
        if (access_token) {
            fetchMaterials()
            fetchPrinters()
            fetchRoles()
        }
    }, [access_token])

    return (
        <div className="userLayout">
            <Header />
            <div className="userLayout_contentBlock">
                <DrawerMenu menuList={menuListAdmin} />
                <div className="outletLayout">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        access_token: state.userEnv.access_token,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchMaterials: () => dispatch(fetchMaterials(1, 100, null)),
        fetchPrinters: () => dispatch(fetchPrinters(1, 100, null)),
        fetchRoles: () => dispatch(fetchRoles())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);