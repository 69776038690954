import { api_orders } from 'api/admin/orders'
import { errorNotification, successNotification } from 'hooks/notifications'
import {
    ADMIN_FETCH_ORDER_BY_ID_DATA_START,
    ADMIN_FETCH_ORDER_BY_ID_DATA_SUCCESS,
    FETCH_ALL_ORDERS_START,
    FETCH_ALL_ORDERS_SUCCESS,
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'

export function fetchAllOrders(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_ORDERS_START))
        try {
            const orders = await api_orders.GetOrders(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_ORDERS_SUCCESS, {
                data: orders.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchOrderById(id) {
    return async dispatch => {
        dispatch(fetchStart(ADMIN_FETCH_ORDER_BY_ID_DATA_START))
        try {
            const order_data = await api_orders.GetOrderById(id)
            dispatch(fetchSuccess(ADMIN_FETCH_ORDER_BY_ID_DATA_SUCCESS, {
                order_by_id_data: order_data.data.data
            }))
        } catch (e) {

        }
    }
}

export function fetchCreateOrder(data) {
    return async dispatch => {
        try {
            await api_orders.PostCreateOrder(data)
            successNotification('Заказ создан', 'Заказ успешно создан')
            return true
        } catch (e) {
            if (e.response.data.detail === 'Resource already exists') {
                errorNotification('Возникла ошибка. Неверный Id заказчика')
                return false
            }
            if (e.response.data.detail[0].msg === 'value is not a valid uuid') {
                errorNotification('Возникла ошибка. Неверный формат Id заказчика')
                return false
            }
        }
    }
}

export function fetchUpdateOrder(order_id, data) {
    return async dispatch => {
        try {
            await api_orders.PutUpdateOrder(order_id, data)
            dispatch(fetchOrderById(order_id))
            successNotification('Заказ обновлен', 'Заказ успешно обновлен')
            return true
        } catch (e) {
            if (e.response.data.detail === 'Resource already exists') {
                errorNotification('Возникла ошибка. Неверный Id заказчика')
                return false
            }
            if (e.response.data.detail[0].msg === 'value is not a valid uuid') {
                errorNotification('Возникла ошибка. Неверный формат Id заказчика')
                return false
            }
        }
    }
}

export function fetchOrderDistribute(order_id) {
    return async dispatch => {
        try {
            await api_orders.PostOrderDistribute(order_id)
            successNotification('Заказ отправлен на распределение', 'Заказ успешно отправлен на распределение')
            dispatch(fetchOrderById(order_id))
        } catch (e) {
            errorNotification('Возникла ошибка')
        }
    }
}

export function fetchOrderDeactivate(order_id) {
    return async dispatch => {
        try {
            await api_orders.PostOrderDeactivate(order_id)
            successNotification('Заказ деактивирован', 'Заказ успешно деактивирован')
            dispatch(fetchOrderById(order_id))
        } catch (e) {
            errorNotification('Возникла ошибка')
        }
    }
}

export function fetchOfferExecutor(order_id, executor_id) {
    return async dispatch => {
        try {
            await api_orders.PostOfferExecutor(order_id, executor_id)
            successNotification('Предложение отправлено', 'Предложение успешно отправлено')
            dispatch(fetchOrderById(order_id))
        } catch (e) {
            errorNotification('Возникла ошибка')
        }
    }
}

export function fetchRejectVerification(order_id, verification_id, comment, requestBody) {
    return async dispatch => {
        try {
            await api_orders.PostRejectVerification(order_id, verification_id, comment, requestBody)
            successNotification('Выполение отклонено', 'Выполение успешно отклонено')
            dispatch(fetchOrderById(order_id))
        } catch (e) {
            errorNotification('Возникла ошибка')
        }
    }
}

export function fetchAcceptVerification(order_id, verification_id, track_num, track_files, code_files) {
    return async dispatch => {
        try {
            await api_orders.PostAcceptVerification(order_id, verification_id, track_num)
            if (track_files) {
                await api_orders.PutOrderUploadTrackFiles(order_id, track_files)
            }
            if(code_files) {
                await api_orders.PutOrderUploadCodeFiles(order_id, code_files)
            }
            successNotification('Выполение подтверждено', 'Выполение успешно подтверждено')
            dispatch(fetchOrderById(order_id))
        } catch (e) {
            errorNotification('Возникла ошибка')
        }
    }
}

export function fetchAcceptVerificationUploadTrackFile(order_id, track_file) {
    return async dispatch => {
        try {
            await api_orders.PostAcceptVerificationUploadTrackFile(order_id, track_file)
            successNotification('Файл обновлен', '')
            dispatch(fetchOrderById(order_id))
        } catch (e) {
            errorNotification('Возникла ошибка')
        }
    }
}

export function fetchSetExecutor(order_id, executor_id) {
    return async dispatch => {
        try {
            await api_orders.PostSetExecutor(order_id, executor_id)
            successNotification('Исполнитель назвачен', 'Исполнитель успешно назначен')
            dispatch(fetchOrderById(order_id))
        } catch (e) {
            errorNotification('Возникла ошибка')
        }
    }
}

export function fetchOrderDistributeWithData(order_id, data) {
    return async dispatch => {
        try {
            await api_orders.PostOrderDistributeWithData(order_id, data)
            successNotification('Распределение перезапущено', 'Распределение успешно перезапущено')
            dispatch(fetchOrderById(order_id))
        } catch (e) {
            errorNotification('Возникла ошибка')
        }
    }
}

export function fetchOrderToggleStopped(order_id) {
    return async dispatch => {
        try {
            await api_orders.PostOrderToggleStopped(order_id)
            successNotification('Статус распределения заказа изменен', 'Статус распределения заказа успешно изменен')
            dispatch(fetchOrderById(order_id))
        } catch (e) {
            errorNotification('Возникла ошибка')
        }
    }
}

export function fetchUploadTrackFiles(order_id, data) {
    return async dispatch => {
        try {
            await api_orders.PutOrderUploadTrackFiles(order_id, data)
            successNotification('Документы добавлены', 'Документы успешно добавлены')
            dispatch(fetchOrderById(order_id))
            return true
        } catch (e) {
            errorNotification('Возникла ошибка')
            return false
        }
    }
}

export function fetchRemoveTrackFiles(order_id, data) {
    return async dispatch => {
        try {
            await api_orders.PutOrderRemoveTrackFiles(order_id, data)
            successNotification('Документ удален', 'Документ успешно удален')
            dispatch(fetchOrderById(order_id))
            return true
        } catch (e) {
            errorNotification('Возникла ошибка')
            return false
        }
    }
}

export function fetchUploadCodeFiles(order_id, data) {
    return async dispatch => {
        try {
            await api_orders.PutOrderUploadCodeFiles(order_id, data)
            successNotification('Документы добавлены', 'Документы успешно добавлены')
            dispatch(fetchOrderById(order_id))
            return true
        } catch (e) {
            errorNotification('Возникла ошибка')
            return false
        }
    }
}

export function fetchRemoveCodeFiles(order_id, data) {
    return async dispatch => {
        try {
            await api_orders.PutOrderRemoveCodeFiles(order_id, data)
            successNotification('Документ удален', 'Документ успешно удален')
            dispatch(fetchOrderById(order_id))
            return true
        } catch (e) {
            errorNotification('Возникла ошибка')
            return false
        }
    }
}