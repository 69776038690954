import { Form, Select } from 'antd';
import { useState } from 'react';
import { connect } from 'react-redux';
import { fetchMyData, fetchToggleMaterials } from 'store/actions/user/user';

const EditMaterials = ({ form, defData, materials, fetchToggleMaterials, fetchMyData }) => {
    const [toggleMaterials, setToggleMaterials] = useState([])
    const [fields, setFields] = useState([
        {
            name: 'materials_id',
            value: defData
        }
    ]);

    const onFinish = async ({ }) => {
        for (let i = 0; i < toggleMaterials.length; i++) {
            await fetchToggleMaterials(toggleMaterials[i])
        }
        await fetchMyData()
        setToggleMaterials([])
    };

    const onSelect = (material_id) => {
        setToggleMaterials([...toggleMaterials, material_id])
    }

    const onDeselect = (material_id) => {
        const arr = [...toggleMaterials]
        let index = toggleMaterials.indexOf(material_id);
        if (index > -1) {
            arr.splice(index, 1)
            setToggleMaterials(arr)
        } else {
            setToggleMaterials([...toggleMaterials, material_id])
        }
    }

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            autoComplete="off"
            className="twoColumnForm"
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
            form={form}
            onFinish={onFinish}
        >
            <Form.Item
                name="materials_id"
                style={{ width: '100%' }}
            >
                <Select
                    placeholder="Выберите материал"
                    style={{ width: '100%' }}
                    mode="multiple"
                    onSelect={(e) => onSelect(e)}
                    onDeselect={(e) => onDeselect(e)}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={materials.map(el => ({
                        value: el.id,
                        label: el.value,
                    }))}
                />
            </Form.Item>
        </Form>
    )
}

function mapStateToProps(state) {
    return {
        materials: state.materials.materials
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchToggleMaterials: (material_id) => dispatch(fetchToggleMaterials(material_id)),
        fetchMyData: () => dispatch(fetchMyData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMaterials)