import { menuListUser } from "data/routings";
import { useEffect } from "react";
import { connect } from 'react-redux';
import { Outlet } from "react-router-dom";
import { fetchMyData } from "store/actions/user/user";
import DrawerMenu from "../../shared/drawerMenu/DrawerManu";
import Header from "./header/Header";

import { fetchMaterials } from "store/actions/materials";
import { fetchPrinters } from "store/actions/printers";
import './style.css';

function UserLayout({
    user_data,
    access_token,
    fetchMyData,
    fetchMaterials,
    fetchPrinters,
}) {

    useEffect(() => {
        if (access_token) {
            fetchMyData()
            fetchMaterials()
            fetchPrinters()
        }
    }, [access_token])

    return (
        user_data &&
        <div className="userLayout">
            <Header />
            <div className="userLayout_contentBlock">
                <DrawerMenu menuList={menuListUser} />
                <div className="outletLayout">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user_data: state.user.user_data,
        access_token: state.userEnv.access_token,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchMyData: () => dispatch(fetchMyData()),
        fetchMaterials: () => dispatch(fetchMaterials(1, 100, null)),
        fetchPrinters: () => dispatch(fetchPrinters(1, 100, null))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLayout);