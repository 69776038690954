import AuthenticationBlock from "components/ui/authenticationBlock/AuthenticationBlock";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import { useState } from "react";

import PasswordRecoveryForm from "components/components/forms/authentication/passwordRecovery/PasswordRecoveryForm";
import { Link } from "react-router-dom";
import '../style.css';

function PasswordRecovery() {
    const [activeSwitch, setActiveSwitch] = useState('email')

    return (
        <AuthenticationBlock>
            <TextHeading text={'Забыли пароль?'} style={{ textAlign: 'center' }} />
            <PasswordRecoveryForm />
            <Link to={'/login'} className="p14_400 activeText">Войти</Link>
        </AuthenticationBlock>
    );
}

export default PasswordRecovery;