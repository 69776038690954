import { Form, Input, Upload } from "antd";
import uploadIcon from 'assets/images/icons/upload.svg';
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import UploadCard from "components/shared/cards/uploadCard/UploadCard";
import { useState } from "react";
import { connect } from 'react-redux';
import { CLOSE_MODAL, SET_VISIBLE_MODAL } from "store/actionTypes";
import { fetchRejectVerification } from "store/actions/admin/orders";

const propsDocsUpload = {
    action: '',
    accept: ".png, .jpg, .jpeg",
    listType: "text",
    multiple: true,
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

const RejectVerificationOrderForm = ({ data, setVisible, goBackModal, fetchRejectVerification }) => {
    const [fileDocsList, setFileDocsList] = useState([])

    const onFinish = ({ comment }) => {
        let requestBody = new FormData();
        for (let i = 0; i < fileDocsList.length; i++) {
            let filedata = fileDocsList[i].originFileObj
            delete filedata.uid
            requestBody.append('images', filedata);
        }
        fetchRejectVerification(data.data.order_id, data.data.id, comment, requestBody)
        setVisible()
    };

    const normFile = (e, type) => {
        setFileDocsList(e.fileList)

        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const deleteFile = (index, type) => {
        const files = [...fileDocsList];
        files.splice(index, 1);
        setFileDocsList(files);

    }

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <Form.Item
                label={'Причина отклонения'}
                name="comment"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите причину отклонения' />
            </Form.Item>
            <div style={{ marginTop: 24 }}>
                <div className={`${fileDocsList.length === 0 ? '' : 'squareUploadBlock'}`}>
                    <div className='squareUploadBlock'>
                        {fileDocsList.map((el, index) => (
                            <UploadCard key={`UploadCard_${index}`} title={el.name || el.media.title} onClick={() => deleteFile(index, 'fileDocsList')} />
                        ))}
                        <Form.Item
                            name="filePhotoList"
                            valuePropName="filePhotoList"
                            getValueFromEvent={(e) => normFile(e, 'fileDocsList')}
                            className={`${fileDocsList.length === 0 ? '' : 'squareUpload'}`}
                            style={{ width: '100%' }}
                        >
                            {fileDocsList.length === 0
                                ?
                                <Upload.Dragger
                                    name="fileDocsList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsDocsUpload}
                                >
                                    <div style={{ width: '100%', margin: '100px auto' }}>
                                        <p className="subtext upload-text">Перетащите сюда, чтобы загрузить файл</p>
                                        <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                    </div>
                                </Upload.Dragger>
                                :
                                <Upload.Dragger
                                    name="fileDocsList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsDocsUpload}
                                    fileList={fileDocsList}
                                >
                                    <img src={uploadIcon} alt="uploadIcon" />
                                </Upload.Dragger>
                            }
                        </Form.Item>
                    </div>
                </div>
            </div>
            <Form.Item
                style={{ marginBottom: 0 }}
            >
                <div>
                    <SimpleButton
                        title={'Отмена'}
                        className="activeText p14_500 whiteButton"
                        style={{
                            marginRight: 16
                        }}
                        onClick={() => goBackModal('verificationOrder', data)}
                    />
                    <SimpleButton
                        title={'Отклонить'}
                        className="p14_500 redButton"
                        htmltype="submit"
                    />
                </div>
            </Form.Item>
        </Form>
    )
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        goBackModal: (modal_type, data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
        fetchRejectVerification: (order_id, verification_id, comment, requestBody) => dispatch(fetchRejectVerification(order_id, verification_id, comment, requestBody)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RejectVerificationOrderForm)