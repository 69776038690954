import { Form, Input } from 'antd';
import FormButton from 'components/shared/buttons/formButton/FormButton';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchPasswordReset } from 'store/actions/login';

function EnterNewPassword({
    loading,
    fetchPasswordReset
}) {
    const [errorStatusFirst, setErrorStatusFirst] = useState({})
    const [errorStatusSecond, setErrorStatusSecond] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    const onFinish = ({replay_password, new_password}) => {
        if (replay_password === new_password) {
            const data = {
                token: searchParams.get('token'),
                new_password
            }
            fetchPasswordReset(data)
                .then(res => {
                    if (res === 'success') navigate('/')
                })
        } else {
            setErrorStatusFirst({
                validateStatus: "error"
            })
            setErrorStatusSecond({
                validateStatus: "error",
                help: "Пароли не совпадают"
            })
        }
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            onFieldsChange={() => {
                setErrorStatusFirst({})
                setErrorStatusSecond({})
            }}
        >
            <Form.Item
                label={'Новый пароль'}
                name="new_password"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
                {...errorStatusFirst}
            >
                <Input.Password placeholder='Введите пароль' />
            </Form.Item>
            <Form.Item
                label={'Повторите пароль'}
                name="replay_password"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
                {...errorStatusSecond}
            >
                <Input.Password placeholder='Введите пароль' />
            </Form.Item>
            <Form.Item>
                <FormButton
                    title={'Восстановить'}
                    style={{ width: '100%' }}
                    htmlType="submit"
                    loading={loading}
                />
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        loading: state.userEnv.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPasswordReset: (data) => dispatch(fetchPasswordReset(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterNewPassword);