import { notification } from "antd";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import Pagination from "components/shared/pagination/Pagination";
import Table from "components/shared/table/Table";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import { operationStatus } from "data/statuses";
import { allUserFinanceTable } from "data/tableHeader";
import { allUserFinanceTableWidth } from "data/tableWidth";
import { createQuery } from "hooks/createQuery";
import { formatDateTime } from "hooks/formatDate";
import { errorNotificationWithButton } from "hooks/notifications";
import { useEffect } from "react";
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from "react-router-dom";
import { SET_VISIBLE_MODAL } from "store/actionTypes";
import { fetchAllOperations } from "store/actions/user/operations";

const AllFinance = ({
    operations,
    pages,
    fetchAllOperations,
    setVisible,
    user_data
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchAllOperations(
                query.page,
                50,
                createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order)
            )
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
        Object.fromEntries([...searchParams]).search,
    ])

    const createTableData = () => {
        return operations.map((el, key) => (
            [
                {
                    type: 'active_text',
                    content: el.order_id ? <span onClick={() => { navigate(`/user/my-orders/${el.order_id}`) }}>{el.order_id}</span> : '-'
                },
                {
                    type: 'text',
                    content: formatDateTime(el.time_created)
                },
                {
                    type: 'text',
                    content: el.amount + '₽' || '-'
                },
                {
                    type: 'text',
                    content: el.operation_type ? 'Пополнение' : 'Снятие'
                },
                {
                    type: 'text',
                    content: <div className='statusOnTable p12_400' style={{ backgroundColor: operationStatus[el.status]?.bg || '#FFC107' }}>{operationStatus[el.status]?.title || 'В ожидании'}</div>
                }
            ]
        ))

    }

    return (
        <div className='contentBlock'>
            {contextHolder}
            <div className='contentBlock_header'>
                <div className='contentBlock_header__leftHalf'>
                    <TextHeading text={'Финансы'} />
                </div>
                <div className='contentBlock_header__rightHalf'>
                    <SimpleButton
                        title={'Вывести средства'}
                        className="blueButton"
                        onClick={() => {
                            if (user_data.organization_name && user_data.legal_address && user_data.inn && user_data.kpp && user_data.ogrnip && user_data.bic && user_data.correspondent_account) {
                                setVisible()
                            } else {
                                errorNotificationWithButton(
                                    api,
                                    'Заполните платежные данные',
                                    'Заполните платежные данные в профиле и',
                                    'Заполнить данные',
                                    () => navigate(`/user/my-profile`)
                                )
                            }
                        }}
                    />
                </div>
            </div>
            <Table
                header={allUserFinanceTable}
                width={allUserFinanceTableWidth}
                data={createTableData()}
            />
            {operations.length > 0 && <Pagination pages={pages} />}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        operations: state.operations.operations,
        pages: state.operations.pages,
        user_data: state.user.user_data,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchAllOperations: (page, size, params) => dispatch(fetchAllOperations(page, size, params)),
        setVisible: () => dispatch({ type: SET_VISIBLE_MODAL, modal_type: 'createOperation' }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllFinance)