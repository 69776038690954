import { api_operations } from 'api/admin/operations'
import { errorNotification, successNotification } from 'hooks/notifications'
import {
    ADMIN_FETCH_ALL_OPERATIONS_START,
    ADMIN_FETCH_ALL_OPERATIONS_SUCCESS
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'

export function fetchAllOperations(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(ADMIN_FETCH_ALL_OPERATIONS_START))
        try {
            const operations = await api_operations.GetOperations(page, size, params)
            dispatch(fetchSuccess(ADMIN_FETCH_ALL_OPERATIONS_SUCCESS, {
                data: operations.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchOperationComplete(operation_id) {
    return async dispatch => {
        try {
            await api_operations.CompleteOperation(operation_id)
            successNotification('Операция подтверждена', 'Операция успешно подтверждена')
        } catch (e) {
            errorNotification('Возникла ошибка')
        }
    }
}

export function fetchOperationReject(operation_id) {
    return async dispatch => {
        try {
            await api_operations.RejectOperation(operation_id)
            successNotification('Операция отклонена', 'Операция успешно отклонена')
        } catch (e) {
            errorNotification('Возникла ошибка')
        }
    }
}
