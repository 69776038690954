import { api_operations } from 'api/user/operations'
import { api_user } from 'api/user/user'
import { errorNotification, successNotification } from 'hooks/notifications'
import {
    FETCH_ALL_OPERATIONS_START,
    FETCH_ALL_OPERATIONS_SUCCESS
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'
import { fetchMyData } from './user'

export function fetchAllOperations(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_OPERATIONS_START))
        try {
            const operations = await api_user.GetMyOperations(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_OPERATIONS_SUCCESS, {
                data: operations.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchCreateOperation(amount) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_OPERATIONS_START))
        try {
            await api_operations.PostCreateOperation(amount)
            successNotification('Операция создана', 'Операция успешно создана')
            dispatch(fetchMyData())
        } catch (e) {
            errorNotification('Возникла ошибка')
        }
    }
}