import { Form, notification } from 'antd'
import deleteIcon from 'assets/images/icons/delete.svg'
import AssignPerformer from 'components/components/forms/assignPerformer/AssignPerformer'
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton'
import Table from 'components/shared/table/Table'
import InfoBlock from 'components/ui/infoBlock/InfoBlock'
import { adminListDistributionStatuses, deleteDistributeList } from 'data/list'
import { distributionsStatus } from 'data/statuses'
import { distributionsInOrderTable } from 'data/tableHeader'
import { distributionsInOrderTableWidth } from 'data/tableWidth'
import { createFio } from 'hooks/createFio'
import { confirmNotification } from 'hooks/notifications'
import { useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SET_VISIBLE_MODAL } from 'store/actionTypes'
import { fetchDeleteDistribution } from 'store/actions/admin/distribution'
import { fetchOfferExecutor, fetchOrderToggleStopped } from 'store/actions/admin/orders'

const Executors = ({
    order_by_id_data,
    fetchOfferExecutor,
    setVisible,
    fetchOrderToggleStopped,
    fetchDeleteDistribution
}) => {
    const { id } = useParams()
    const [editExecutor, setEditExecutor] = useState(false)
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const editStatus = (newStatus) => {
        let status = adminListDistributionStatuses.filter(el => el.title === newStatus)[0].status
        if (status === 'ACCEPTED') {
            // fetchAcceptOrderById(id)
        } else {

        }
    }

    const createDistributionsTableData = () => {
        return order_by_id_data.distributions.map(el => (
            [
                {
                    type: 'text',
                    content: el.email || '-'
                },
                {
                    type: 'text',
                    content: el.time_sended || '-'
                },
                {
                    type: 'wrap_text',
                    content: el.rejection_reason || '-'
                },
                {
                    type: 'text',
                    content:
                        // el.status === 'CANCELED' //WAITING
                        //     ? <Selector
                        //         list={adminListDistributionStatuses}
                        //         activeItem={distributionsStatus[el.status].title}
                        //         setActiveSelect={editStatus}
                        //         style={{
                        //             backgroundColor: distributionsStatus[el.status].bg
                        //         }}
                        //     />
                        //     : 
                        <div className='statusOnTable p12_400' style={{ backgroundColor: distributionsStatus[el.status].bg }}>{distributionsStatus[el.status].title}</div>
                },
                {
                    type: 'actions',
                    content:
                        // <SimpleButton title={'Предложить'} className="whiteButton" onClick={() => fetchOfferExecutor(id, el.vendor_id)} />
                        deleteDistributeList.indexOf(el.status) === -1 && <div style={{ textAlign: 'end' }}>
                            <img
                                src={deleteIcon}
                                alt='deleteIcon'
                                style={{ cursor: 'pointer' }}
                                onClick={() => confirmNotification(api, async () => {
                                    fetchDeleteDistribution(el.id, id)
                                    api.destroy()
                                }, 'удаление исполнителя из очереди.')}
                            />
                        </div>
                },
            ]
        ))
    }

    const onClickEditExecutor = () => {
        if (editExecutor) {
            form.submit()
        }
        setEditExecutor(!editExecutor)
    }

    return (
        <>
            {contextHolder}
            <div className='contentBlock_header' style={{ marginTop: 24 }}>
                <p className='p18_500 mainText'>Исполнитель</p>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        gap: 12,

                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                        <SimpleButton
                            title={editExecutor ? 'Сохранить' : 'Назначить исполнителя'}
                            className="blueButton"
                            onClick={onClickEditExecutor}
                        />
                        <SimpleButton
                            title={order_by_id_data.status === 'DISTIBUTING' ? 'Остановить распределение' : 'Запустить распределение'}
                            className={order_by_id_data.status === 'DISTIBUTING' ? "redButton" : "greenButton"}
                            onClick={() => confirmNotification(api, async () => {
                                await fetchOrderToggleStopped(order_by_id_data.id)
                                api.destroy()
                            }, order_by_id_data.status === 'DISTIBUTING' ? 'остановку распределения.' : 'восстановление распределения. Email рассылка начнется в течение 15-ти минут')}
                        />
                    </div>
                </div>
            </div>
            {editExecutor
                ? <AssignPerformer
                    defData={order_by_id_data.vendor_id}
                    form={form}
                    order_id={order_by_id_data.id}
                    onFinishFunc={() => setEditExecutor(false)}
                />
                :
                <InfoBlock >
                    {order_by_id_data.vendor_id
                        ?
                        <div>
                            <p className='p12_400 mainText p_mb8'>ФИО исполнителя</p>
                            <p className='p14_400 mainText p_mb16'>{createFio(order_by_id_data.vendor)}</p>
                            <p className='p12_400 mainText p_mb8'>Email исполнителя</p>
                            <p className='p14_400 mainText p_mb16'>{order_by_id_data.vendor.email || '-'}</p>
                            <p className='p12_400 mainText p_mb8'>Номер телефона исполнителя</p>
                            <p className='p14_400 mainText'>{order_by_id_data.vendor.phone || '-'}</p>
                        </div>
                        : <p className='subtext p14_400'>Исполнитель не назначен</p>
                    }
                </InfoBlock>
            }
            <div className='contentBlock_header' style={{ marginTop: 24 }}>
                <p className='p18_500 mainText p_mb8 p_mt24'>Все исполнители</p>
                <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                    <SimpleButton
                        title={'Сформировать очередь'}
                        className="blueButton"
                        onClick={() => confirmNotification(api, async () => {
                            setVisible('orderDistribute', { order_id: order_by_id_data.id })
                            api.destroy()
                        }, 'формирование очереди.')}
                    />
                    <SimpleButton
                        title={'Добавить исполнителя в очередь'}
                        className="blueButton"
                        onClick={() => confirmNotification(api, async () => {
                            setVisible('createDistribution', { order_id: order_by_id_data.id, distributions: order_by_id_data.distributions })
                            api.destroy()
                        }, 'формирование очереди.')}
                    />
                </div>
            </div>
            <Table
                header={distributionsInOrderTable}
                width={distributionsInOrderTableWidth}
                data={createDistributionsTableData()}
            />
        </>
    )
}

function mapStateToProps(state) {
    return {
        order_by_id_data: state.adminOrderById.order_by_id_data,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchOfferExecutor: (order_id, executor_id) => dispatch(fetchOfferExecutor(order_id, executor_id)),
        setVisible: (modal_type, data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
        fetchOrderToggleStopped: (order_id) => dispatch(fetchOrderToggleStopped(order_id)),
        fetchDeleteDistribution: (distribution_id, order_id) => dispatch(fetchDeleteDistribution(distribution_id, order_id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Executors)