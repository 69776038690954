import { api_materials } from 'api/materials'
import {
    FETCH_ALL_MATERIALS_START,
    FETCH_ALL_MATERIALS_SUCCESS,
} from '../actionTypes'
import { fetchStart, fetchSuccess } from './fetchStatuses'

export function fetchMaterials(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_MATERIALS_START))
        try {
            const materials = await api_materials.GetMaterials(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_MATERIALS_SUCCESS, {
                data: materials.data.data
            }))
        } catch (e) {
        }
    }
}