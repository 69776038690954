import { api_orders } from 'api/user/order'
import { errorNotification, successNotification } from 'hooks/notifications'
import {
    FETCH_ALL_VENDOR_ACCEPTED_ORDERS_START,
    FETCH_ALL_VENDOR_ACCEPTED_ORDERS_SUCCESS,
    FETCH_ALL_VENDOR_ORDERS_START,
    FETCH_ALL_VENDOR_ORDERS_SUCCESS,
    FETCH_ORDER_BY_ID_DATA_START,
    FETCH_ORDER_BY_ID_DATA_SUCCESS,
} from '../../actionTypes'
import { fetchStart, fetchSuccess } from '../fetchStatuses'

export function fetchAllOrders(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_VENDOR_ORDERS_START))
        try {
            const orders = await api_orders.GetVendorList(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_VENDOR_ORDERS_SUCCESS, {
                data: orders.data.data,
                queue_places: orders.data.meta.queue_places
            }))
        } catch (e) {
        }
    }
}

export function fetchAllAcceptedOrders(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_VENDOR_ACCEPTED_ORDERS_START))
        try {
            const orders = await api_orders.GetVendorAccepted(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_VENDOR_ACCEPTED_ORDERS_SUCCESS, {
                data: orders.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchOrderById(id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ORDER_BY_ID_DATA_START))
        try {
            const order_data = await api_orders.GetOrderById(id)
            dispatch(fetchSuccess(FETCH_ORDER_BY_ID_DATA_SUCCESS, {
                order_by_id_data: order_data.data.data
            }))
        } catch (e) {

        }
    }
}

export function fetchOrderPublicById(id) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ORDER_BY_ID_DATA_START))
        try {
            const order_data = await api_orders.GetOrderPublicById(id)
            dispatch(fetchSuccess(FETCH_ORDER_BY_ID_DATA_SUCCESS, {
                order_by_id_data: order_data.data.data
            }))
        } catch (e) {

        }
    }
}

export function fetchAcceptOrderById(order_id) {
    return async dispatch => {
        try {
            await api_orders.PostAcceptOrderById(order_id)
            successNotification('Заказ подтвержден', 'Заказ успешно подтвержден')
            dispatch(fetchOrderById(order_id))
            return true
        } catch (e) {
            errorNotification('Возникла ошибка')
            return false
        }
    }
}

export function fetchRejectOrderById(order_id, rejection_reason) {
    return async dispatch => {
        try {
            await api_orders.PostRejectOrderById(order_id, rejection_reason)
            successNotification('Заказ отклонен', 'Заказ успешно отклонен')
            dispatch(fetchOrderById(order_id))
            return true
        } catch (e) {
            errorNotification('Возникла ошибка')
            return false
        }
    }
}

export function fetchCompliteOrderById(order_id, data) {
    return async dispatch => {
        try {
            await api_orders.PostCompliteOrderById(order_id, data)
            successNotification('Заказ завершен', 'Заказ успешно завершен')
            dispatch(fetchOrderById(order_id))
            return true
        } catch (e) {
            errorNotification('Возникла ошибка')
            return false
        }
    }
}