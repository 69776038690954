import CreateDistributionForm from "components/components/forms/modal/createDistribution/CreateDistributionForm"
import Search from "components/shared/search/Search"
import { useEffect, useState } from "react"
import { connect } from 'react-redux'
import { fetchAllVenders } from "store/actions/admin/users"

const CreateDistribution = ({ fetchAllVenders }) => {
    const [search, setSearch] = useState('')

    useEffect(() => {
        fetchAllVenders(1, 100, null, search)
    }, [search])

    useEffect(() => {
        return () => fetchAllVenders(1, 100, null);
    }, [])

    return (
        <div>
            <p className="mainText p24_600 p_mb8" style={{ textAlign: 'center' }}>Добавление исполнителя</p>
            <p className="subtext p_mb16" style={{ textAlign: 'center' }}>Вы можете выбрать исполнителей, которых хотите добавить в очередь</p>
            <Search
                value={search}
                setValue={setSearch}
                style={{ marginBottom: 24 }}
            />
            <CreateDistributionForm />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        order_by_id_data: state.adminOrderById.order_by_id_data,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchAllVenders: (page, size, params, search_str) => dispatch(fetchAllVenders(page, size, params, search_str)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDistribution)