export const adminOrderById = [
    {
        item: 'mainInfo',
        title: 'Основная информация'
    },
    {
        item: 'executors',
        title: 'Исполнители'
    },
    {
        item: 'verification',
        title: 'Проверка'
    },
]

export const adminUserById = [
    {
        item: 'mainInfo',
        title: 'Основная информация'
    },
]

export const userOrderById = [
    {
        item: 'mainInfo',
        title: 'Основная информация'
    },
    {
        item: 'verification',
        title: 'Проверка'
    },
]

export const adminFinance = [
    {
        item: true,
        title: 'Поступления'
    },
    {
        item: false,
        title: 'Списания'
    },
]