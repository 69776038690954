export const FETCH_LOGIN_START = 'FETCH_LOGIN_START'
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS'
export const FETCH_REFRESH_TOKEN_SUCCESS = 'FETCH_REFRESH_TOKEN_SUCCESS'
export const SAVE_DATA_FOR_ACTIVE = 'SAVE_DATA_FOR_ACTIVE'
export const FETCH_LOGIN_ERROR = 'FETCH_LOGIN_ERROR'
export const EXIT = 'EXIT'

/*Modal */
export const SET_VISIBLE_MODAL = 'SET_VISIBLE_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const CHANGE_MODAL_TYPE = 'CHANGE_MODAL_TYPE'

/*Printers */
export const FETCH_ALL_PRINTERS_START = 'FETCH_ALL_PRINTERS_START'
export const FETCH_ALL_PRINTERS_SUCCESS = 'FETCH_ALL_PRINTERS_SUCCESS'

/*Materials */
export const FETCH_ALL_MATERIALS_START = 'FETCH_ALL_MATERIALS_START'
export const FETCH_ALL_MATERIALS_SUCCESS = 'FETCH_ALL_MATERIALS_SUCCESS'

/*Roles */
export const FETCH_ALL_ROLES_START = 'FETCH_ALL_ROLES_START'
export const FETCH_ALL_ROLES_SUCCESS = 'FETCH_ALL_ROLES_SUCCESS'

/*Admin orders */
export const FETCH_ALL_ORDERS_START = 'FETCH_ALL_ORDERS_START'
export const FETCH_ALL_ORDERS_SUCCESS = 'FETCH_ALL_ORDERS_SUCCESS'
export const ADMIN_FETCH_ORDER_BY_ID_DATA_START = 'ADMIN_FETCH_ORDER_BY_ID_DATA_START'
export const ADMIN_FETCH_ORDER_BY_ID_DATA_SUCCESS = 'ADMIN_FETCH_ORDER_BY_ID_DATA_SUCCESS'
export const ADMIN_CLEAR_ORDER_BY_ID_DATA = 'ADMIN_CLEAR_ORDER_BY_ID_DATA'

/*Admin operations */
export const ADMIN_FETCH_ALL_OPERATIONS_START = 'ADMIN_FETCH_ALL_OPERATIONS_START'
export const ADMIN_FETCH_ALL_OPERATIONS_SUCCESS = 'ADMIN_FETCH_ALL_OPERATIONS_SUCCESS'

/*Admin venders */
export const FETCH_ALL_VENDERS_START = 'FETCH_ALL_VENDERS_START'
export const FETCH_ALL_VENDERS_SUCCESS = 'FETCH_ALL_VENDERS_SUCCESS'
export const FETCH_ALL_VENDERS_SUCCESS_CONCAT = 'FETCH_ALL_VENDERS_SUCCESS_CONCAT'

/*Admin users*/
export const ADMIN_CLEAR_USER_BY_ID_DATA = 'ADMIN_CLEAR_USER_BY_ID_DATA'
export const ADMIN_FETCH_USER_BY_ID_DATA_START = 'ADMIN_FETCH_USER_BY_ID_DATA_START'
export const ADMIN_FETCH_USER_BY_ID_DATA_SUCCESS = 'ADMIN_FETCH_USER_BY_ID_DATA_SUCCESS'

/*User orders */
export const FETCH_ALL_VENDOR_ORDERS_START = 'FETCH_ALL_VENDOR_ORDERS_START'
export const FETCH_ALL_VENDOR_ORDERS_SUCCESS = 'FETCH_ALL_VENDOR_ORDERS_SUCCESS'
export const FETCH_ALL_VENDOR_ACCEPTED_ORDERS_START = 'FETCH_ALL_VENDOR_ACCEPTED_ORDERS_START'
export const FETCH_ALL_VENDOR_ACCEPTED_ORDERS_SUCCESS = 'FETCH_ALL_VENDOR_ACCEPTED_ORDERS_SUCCESS'
export const FETCH_ORDER_BY_ID_DATA_START = 'FETCH_ORDER_BY_ID_DATA_START'
export const FETCH_ORDER_BY_ID_DATA_SUCCESS = 'FETCH_ORDER_BY_ID_DATA_SUCCESS'
export const CLEAR_ORDER_BY_ID_DATA = 'CLEAR_ORDER_BY_ID_DATA'

/*User data */
export const FETCH_USER_DATA_START = 'FETCH_USER_DATA_START'
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS'
export const CLEARE_USER_DATA = 'CLEARE_USER_DATA'

/*User operations */
export const FETCH_ALL_OPERATIONS_START = 'FETCH_ALL_VENDERS_START'
export const FETCH_ALL_OPERATIONS_SUCCESS = 'FETCH_ALL_VENDERS_SUCCESS'