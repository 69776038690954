import { Form, Input } from 'antd';
import FormButton from 'components/shared/buttons/formButton/FormButton';
import { connect } from 'react-redux';
import { fetchLogin } from 'store/actions/login';

function EmailLogin({
    loading, 
    fetchLogin 
}) {

    const onFinish = (values) => {
        fetchLogin(values)
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <Form.Item
                label={'Электронная почта'}
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите почту' />
            </Form.Item>
            <Form.Item
                label={'Пароль'}
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input.Password placeholder='Введите пароль' />
            </Form.Item>
            <Form.Item>
                <FormButton
                    title={'Войти'}
                    style={{ width: '100%' }}
                    htmlType="submit"
                    loading={loading}
                />
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        loading: state.userEnv.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchLogin: (data) => dispatch(fetchLogin(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailLogin);