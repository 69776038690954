import {
    FETCH_ALL_OPERATIONS_START,
    FETCH_ALL_OPERATIONS_SUCCESS
} from '../../actionTypes'

const initialState = {
    operations: [],
    pages: 0,
    page: 0,
    size: 0,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_OPERATIONS_START:
            return {
                ...state, loading: true
            }
        case FETCH_ALL_OPERATIONS_SUCCESS:
            return {
                operations: action.data.data.items,
                pages: action.data.data.pages,
                page: action.data.data.page,
                size: action.data.data.size,
                loading: false
            }
        default: return state
    }
}