import CreateOperationForm from "components/components/forms/modal/createOperation/CreateOperationForm";
import { connect } from 'react-redux';

const CreateOperation = ({ user_data }) => {
    return (
        <div>
            <p className="mainText p24_600 p_mb8" style={{ textAlign: 'center', width: '30vw' }}>Создать операцию</p>
            <p className="subtext p_mb8" style={{ textAlign: 'center' }}>Перед созданием операции введите сумму операции.</p>
            <p className="p16_500 p_mb4" style={{ textAlign: 'center' }}>Ваш баланс: {(Math.floor(user_data.balance)).toFixed(0)} ₽</p>
            <p className="p12_400 p_mb16" style={{ textAlign: 'center' }}>(минимальная сумма на вывод: 1000₽)</p>
            <CreateOperationForm />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user_data: state.user.user_data,
    }
}

export default connect(mapStateToProps, null)(CreateOperation)