import { ApiUrl, instance } from '../apiEnv';

export const api_distribution = {

    async CreateDistribute(data) {
        return await instance.post(`${ApiUrl}distribution`, data)
    },

    async DeleteDistribute(distribution_id) {
        return await instance.delete(`${ApiUrl}distribution/${distribution_id}`)
    },

}