import EnterNewPassword from "components/components/forms/authentication/enterNewPassword/EnterNewPassword";
import AuthenticationBlock from "components/ui/authenticationBlock/AuthenticationBlock";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import '../style.css';

function NewPassword() {
    return (
        <AuthenticationBlock>
            <TextHeading text={'Восстановление пароля'} style={{ textAlign: 'center' }} />
            <div style={{marginTop: 24}}>
                <EnterNewPassword />
            </div>
        </AuthenticationBlock>
    );
}

export default NewPassword;