import { Select, notification } from 'antd'
import eye from 'assets/images/icons/eye.svg'
import Filter from 'components/shared/filter/Filter'
import Pagination from 'components/shared/pagination/Pagination'
import Switch from 'components/shared/switch/Switch'
import Table from 'components/shared/table/Table'
import TextHeading from 'components/ui/teaxtHeading/TextHeading'
import { adminFinanceFilter } from 'data/filterLIst'
import { operationStatus } from 'data/statuses'
import { adminFinance } from 'data/switchList'
import { allAdminFinanceTable } from 'data/tableHeader'
import { allAdminFinanceTableWidth } from 'data/tableWidth'
import { createQuerySecondType, getQueryStringFromObject } from 'hooks/createQuery'
import { formatDateTime } from 'hooks/formatDate'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SET_VISIBLE_MODAL } from 'store/actionTypes'
import { fetchAllOperations } from 'store/actions/admin/operations'

const AllFinanceAdmin = ({
    operations,
    pages,
    fetchAllOperations,
    setVisible,
    venders
}) => {
    const [api, contextHolder] = notification.useNotification();
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState('')
    const [searchExecutor, setSearchExecutor] = useState('')
    const [activeSwitch, setActiveSwitch] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchAllOperations(
                query.page,
                50,
                createQuerySecondType(query.search, query.order_by, query.order)
            )
        } else {
            query.page = 1
            setSearchParams(query)
        }
        if (query.search) {
            const search = JSON.parse(decodeURIComponent(query.search))
            setActiveSwitch(search.operation_type);
            query.search = getQueryStringFromObject(JSON.stringify(search))
        } else {
            query.search = getQueryStringFromObject(JSON.stringify({
                operation_type: true
            }))
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).order_by,
        Object.fromEntries([...searchParams]).order
    ])

    const createTableData = () => {
        return operations.map(el => (
            [
                {
                    type: 'active_text',
                    content: el.order_id ? <span onClick={() => { navigate(`/admin/orders/${el.order_id}`) }}>{el.order_id}</span> : '-'
                },
                {
                    type: 'active_text',
                    content: el.user_id ? <span onClick={() => { navigate(`/admin/venders/${el.user_id}`) }}>{el.user.email}</span> : '-'
                },
                {
                    type: 'text',
                    content: formatDateTime(el.time_created)
                },
                {
                    type: 'text',
                    content: el.amount + '₽' || '-'
                },
                {
                    type: 'text',
                    content: el.operation_type ? 'Поступления' : 'Списания'
                },
                {
                    type: 'text',
                    content: <div className='statusOnTable p12_400' style={{ backgroundColor: operationStatus[el.status].bg }}>{operationStatus[el.status].title}</div>
                },
                {
                    type: 'actions',
                    content: el.status === 'WAITING'
                        ? <div style={{ textAlign: 'end' }}>
                            <img
                                src={eye}
                                alt='eye'
                                style={{ cursor: 'pointer' }}
                                onClick={() => setVisible('operationResponse', el)}
                            />
                        </div>
                        : null
                },
            ]
        ))

    }

    const onSwitch = (e) => {
        const query = Object.fromEntries([...searchParams]);
        const search = JSON.parse(decodeURIComponent(query.search))
        search.operation_type = e
        query.search = getQueryStringFromObject(JSON.stringify(search))
        setActiveSwitch(e)
        setSearchParams(query)
    }

    const onSelect = (e) => {
        const query = Object.fromEntries([...searchParams]);
        const search = JSON.parse(decodeURIComponent(query.search))
        search.user_id = e
        query.search = getQueryStringFromObject(JSON.stringify(search))
        setSearchParams(query)
    }

    const onClear = () => {
        const query = Object.fromEntries([...searchParams]);
        const search = JSON.parse(decodeURIComponent(query.search))
        delete search.user_id
        query.search = getQueryStringFromObject(JSON.stringify(search))
        setSearchParams(query)
    }

    return (
        <div className='contentBlock'>
            {contextHolder}
            <div className='contentBlock_header'>
                <div className='contentBlock_header__leftHalf'>
                    <TextHeading text={'Финансы'} />
                </div>
                <div className='contentBlock_header__rightHalf' style={{ gap: '12px' }}>
                    <Filter
                        filterList={adminFinanceFilter}
                        blockFilterList={['operation_type', 'user_id']}
                    />
                </div>
            </div>
            <div className='contentBlock_header'>
                <div className='contentBlock_header__leftHalf'>
                    <Switch
                        items={adminFinance}
                        activeItem={activeSwitch}
                        onSwitch={onSwitch}
                        className="switch miniSwitch"
                    />
                </div>
                <div className='contentBlock_header__rightHalf' style={{ gap: '12px' }}>
                    <Select
                        placeholder='Выберите пользователя'
                        showSearch={true}
                        allowClear
                        style={{ width: 300 }}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        onSelect={(e) => onSelect(e)}
                        onClear={onClear}
                        options={venders.map(el => ({
                            value: el.id,
                            label: el.email + ' - ' + el.first_name + ' ' + el.last_name,
                        }))}
                    />
                </div>
            </div>
            <Table
                header={allAdminFinanceTable}
                width={allAdminFinanceTableWidth}
                data={createTableData()}
            />
            {operations.length > 0 && <Pagination pages={pages} />}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        operations: state.adminOperations.operations,
        pages: state.adminOperations.pages,
        venders: state.venders.venders
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchAllOperations: (page, size, params) => dispatch(fetchAllOperations(page, size, params)),
        setVisible: (modal_type, data) => dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllFinanceAdmin)