import { Form, Input, Select, notification } from 'antd';
import editPen from 'assets/images/icons/edit_pen.svg';
import { successNotification, warningNotification } from 'hooks/notifications';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchChangePassword, fetchToggleMaterials, fetchTogglePrinters, fetchUpdateUser } from 'store/actions/admin/users';

function EditMainInfoForm({
    form,
    initial,
    onSave,
    coords,
    user_by_id_data,
    materials,
    printers,
    fetchUpdateUser,
    fetchTogglePrinters,
    fetchToggleMaterials,
    fetchChangePassword
}) {
    const [editPassword, setEditPassword] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const { id } = useParams()

    const onFinish = async (values) => {
        const initMaterials = user_by_id_data.materials.map(el => el.id)
        const deleteMaterials = initMaterials?.filter(el => values.materials_id.indexOf(el) < 0)
        const addMaterials = values?.materials_id?.filter(el => initMaterials?.indexOf(el) < 0)
        const editMaterials = deleteMaterials.concat(addMaterials)

        const initPrinters = user_by_id_data.printers.map(el => el.id)
        const deletePrinters = initPrinters?.filter(el => values.printers_id.indexOf(el) < 0)
        const addPrinters= values?.printers_id?.filter(el => initPrinters?.indexOf(el) < 0)
        const editPrinters = deletePrinters.concat(addPrinters)

        for (let i = 0; i < editMaterials.length; i++) {
            await fetchToggleMaterials(id, editMaterials[i])            
        }

        for (let i = 0; i < editPrinters.length; i++) {
            await fetchTogglePrinters(id, editPrinters[i])            
        }

        if(editPassword){
            fetchChangePassword(values.password, id)
        }
        delete values.password
        delete values.printers_id
        delete values.materials_id
        values.phone = '+7' + values.phone
        if (coords) {
            values.latitude = coords[0]
            values.longitude = coords[1]
        }
        
        fetchUpdateUser(id, values).then(res => {
            if(res) successNotification('Исполнитель отредактирован', 'Исполнитель успешно отредактирован')
        })
        onSave()
    };

    return (
        <>
            {contextHolder}
            <Form
                layout="vertical"
                requiredMark={false}
                initialValues={initial}
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                className="modalForm"
            >
                <Form.Item
                    label={'Имя'}
                    name="first_name"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите имя' />
                </Form.Item>
                <Form.Item
                    label={'Фамилия'}
                    name="last_name"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите фамилию' />
                </Form.Item>
                <Form.Item
                    label={'Номер телефона'}
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='0000000000' prefix={'+7'} type="number" />
                </Form.Item>
                <Form.Item
                    label={'Email'}
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите email' />
                </Form.Item>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 12,
                        width: '100%'
                    }}
                >
                    <Form.Item
                        label={'Пароль'}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Это обязательное поле',
                            },
                        ]}
                    >
                        <Input.Password placeholder='Введите пароль' disabled={!editPassword} />
                    </Form.Item>
                    {!editPassword &&
                        <img
                            src={editPen}
                            alt='editPen'
                            width={15}
                            style={{ cursor: 'pointer' }}
                            onClick={() => warningNotification(api, () => {
                                setEditPassword(true)
                                form.setFieldValue('password', '')
                                api.destroy()
                            }, 'иззменение пароля.')}
                        />
                    }
                </div>
                <Form.Item
                    label={'Адрес'}
                    name="address"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите адрес' />
                </Form.Item>
                <Form.Item
                    label={'Комиссия'}
                    name="commission"
                    rules={[
                        {
                            required: true,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите комиссию' type="number" />
                </Form.Item>
                <Form.Item
                    label={'Название организации'}
                    name="organization_name"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите название организации' />
                </Form.Item>
                <Form.Item
                    label={'Юридический адрес'}
                    name="legal_address"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите юридический адрес' />
                </Form.Item>
                <Form.Item
                    label={'ИНН'}
                    name="inn"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите ИНН' />
                </Form.Item>
                <Form.Item
                    label={'КПП'}
                    name="kpp"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите КПП' />
                </Form.Item>
                <Form.Item
                    label={'ОГРН'}
                    name="ogrnip"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите ОГРН' />
                </Form.Item>
                <Form.Item
                    label={'Название банка'}
                    name="bank_name"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите название банка' />
                </Form.Item>
                <Form.Item
                    label={'Расчетный счет'}
                    name="bank_account"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите Расчетный счет' />
                </Form.Item>
                <Form.Item
                    label={'БИК банк'}
                    name="bic"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите БИК банк' />
                </Form.Item>
                <Form.Item
                    label={'Корреспондентский счет банка'}
                    name="correspondent_account"
                    rules={[
                        {
                            required: false,
                            message: 'Это обязательное поле',
                        },
                    ]}
                >
                    <Input placeholder='Введите Корреспондентский счет банка' />
                </Form.Item>
                <Form.Item
                    label={'Материалы'}
                    name="materials_id"
                    style={{ width: '100%' }}
                >
                    <Select
                        placeholder="Выберите материал"
                        style={{ width: '100%' }}
                        mode="multiple"
                        // onSelect={(e) => onSelect(e)}
                        // onDeselect={(e) => onDeselect(e)}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={materials.map(el => ({
                            value: el.id,
                            label: el.value,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label={'Принтеры'}
                    name="printers_id"
                    style={{ width: '100%' }}
                >
                    <Select
                        placeholder="Выберите принтеры"
                        style={{ width: '100%' }}
                        mode="multiple"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={printers.map(el => ({
                            value: el.id,
                            label: el.value,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label={'Активен'}
                    name="is_active"
                    style={{ width: '100%' }}
                >
                    <Select
                        placeholder="Выберите статус активности"
                        style={{ width: '100%' }}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={[
                            {
                                value: true,
                                label: 'Да',
                            },
                            {
                                value: false,
                                label: 'Нет',
                            }
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    label={'Верифицирован'}
                    name="is_verified"
                    style={{ width: '100%' }}
                >
                    <Select
                        placeholder="Выберите статус верификации"
                        style={{ width: '100%' }}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={[
                            {
                                value: true,
                                label: 'Да',
                            },
                            {
                                value: false,
                                label: 'Нет',
                            }
                        ]}
                    />
                </Form.Item>
            </Form>
        </>
    );
}

function mapStateToProps(state) {
    return {
        materials: state.materials.materials,
        printers: state.printers.printers,
        roles: state.roles.roles,
        user_by_id_data: state.adminUserById.user_by_id_data,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchUpdateUser: (user_id, user_data) => dispatch(fetchUpdateUser(user_id, user_data)),
        fetchTogglePrinters: (user_id, printer_id) => dispatch(fetchTogglePrinters(user_id, printer_id)),
        fetchToggleMaterials: (user_id, material_id) => dispatch(fetchToggleMaterials(user_id, material_id)),
        fetchChangePassword: (password, user_id) => dispatch(fetchChangePassword(password, user_id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMainInfoForm);