import RejectVerificationOrderForm from "components/components/forms/modal/verificationOrder/rejectVerificationOrder/RejectVerificationOrderForm"

const RejectVerificationOrder = () => {
  return (
    <div style={{maxWidth: 657}}>
        <p className="mainText p24_600 p_mb8" style={{ textAlign: 'center', width: '30vw' }}>Отклонение выполненной работы</p>
        <RejectVerificationOrderForm />
    </div>
  )
}

export default RejectVerificationOrder