import AddDocumentsOnOrderForm from "components/components/forms/modal/addDocumentsOnOrder/AddDocumentsOnOrderForm"

const AddDocumentsOnOrder = () => {
  return (
    <div style={{maxWidth: 657}}>
        <p className="mainText p24_600 p_mb8" style={{ textAlign: 'center' }}>Добавить документ</p>
        <p className="subtext p_mb16">Перед добавлением документа необходимо выбрать тип документа</p>
        <AddDocumentsOnOrderForm />
    </div>
  )
}

export default AddDocumentsOnOrder