import search from 'assets/images/icons/search.svg';

import './style.css';

function Search({ className, value, setValue, placeholder, ...props }) {
    return (
        <div
            className={`search ${className}`}
            {...props}
        >
            <img src={search} alt="search" />
            <input placeholder={placeholder || 'Поиск...'} value={value} onChange={(e) => setValue(e.target.value)} />
        </div>
    );
}

export default Search;