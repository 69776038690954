import { FullscreenControl, GeolocationControl, Map, Placemark, SearchControl, TypeSelector } from '@pbe/react-yandex-maps';
import { Form, Input, Select } from 'antd';
import FormButton from 'components/shared/buttons/formButton/FormButton';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchCreateUser, fetchToggleMaterials, fetchTogglePrinters } from 'store/actions/admin/users';

function CreateVenderForm({
    materials,
    printers,
    roles,
    fetchCreateUser,
    fetchTogglePrinters,
    fetchToggleMaterials
}) {
    const [coords, setCoords] = useState(null)
    const navigate = useNavigate()

    const onFinish = async (values) => {
        if (coords) {
            values.latitude = coords[0]
            values.longitude = coords[1]
        }
        values.is_superuser = false
        values.is_verified = true
        values.is_active = true
        values.phone = '+7' + values.phone
        values.role_id = roles.find(el => el.name === 'vendor').id
        fetchCreateUser(values)
            .then(async res => {
                if (res) {
                    if (values.materials_id) {
                        for (let i = 0; i < values.materials_id.length; i++) {
                            await fetchToggleMaterials(res.id, values.materials_id[i])
                        }
                    }
                    if (values.printers_id) {
                        for (let i = 0; i < values.printers_id.length; i++) {
                            await fetchTogglePrinters(res.id, values.printers_id[i])
                        }
                    }
                    navigate(`/admin/venders`)
                }
            })
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
            style={{ marginTop: 16 }}
        >
            <Form.Item
                label={'Имя'}
                name="first_name"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите имя' />
            </Form.Item>
            <Form.Item
                label={'Фамилия'}
                name="last_name"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите фамилию' />
            </Form.Item>
            <Form.Item
                label={'Номер телефона'}
                name="phone"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                        pattern: new RegExp('^[0-9]*$')
                    },
                ]}
            >
                <Input placeholder='0000000000' prefix={'+7'} type='number' min={0} />
            </Form.Item>
            <Form.Item
                label={'Email'}
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите email' />
            </Form.Item>
            <Form.Item
                label={'Пароль'}
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input.Password placeholder='Введите пароль' />
            </Form.Item>
            <Form.Item
                label={'Название организации'}
                name="organization_name"
                rules={[
                    {
                        required: false,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите название организации' />
            </Form.Item>
            <Form.Item
                label={'Юридический адрес'}
                name="legal_address"
                rules={[
                    {
                        required: false,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите юридический адрес' />
            </Form.Item>
            <Form.Item
                label={'ИНН'}
                name="inn"
                rules={[
                    {
                        required: false,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите ИНН' />
            </Form.Item>
            <Form.Item
                label={'КПП'}
                name="kpp"
                rules={[
                    {
                        required: false,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите КПП' />
            </Form.Item>
            <Form.Item
                label={'ОГРН'}
                name="ogrnip"
                rules={[
                    {
                        required: false,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите ОГРН' />
            </Form.Item>
            <Form.Item
                label={'Название банка'}
                name="bank_name"
                rules={[
                    {
                        required: false,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите название банка' />
            </Form.Item>
            <Form.Item
                label={'Расчетный счет'}
                name="bank_account"
                rules={[
                    {
                        required: false,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите Расчетный счет' />
            </Form.Item>
            <Form.Item
                label={'БИК банк'}
                name="bic"
                rules={[
                    {
                        required: false,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите БИК банк' />
            </Form.Item>
            <Form.Item
                label={'Корреспондентский счет банка'}
                name="correspondent_account"
                rules={[
                    {
                        required: false,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите Корреспондентский счет банка' />
            </Form.Item>
            <Form.Item
                label={'Комиссия'}
                name="commission"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите комиссию' type="number" />
            </Form.Item>
            <Form.Item
                label={'Материалы'}
                name="materials_id"
                style={{ width: '100%' }}
            >
                <Select
                    placeholder="Выберите материал"
                    style={{ width: '100%' }}
                    mode="multiple"
                    // onSelect={(e) => onSelect(e)}
                    // onDeselect={(e) => onDeselect(e)}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={materials.map(el => ({
                        value: el.id,
                        label: el.value,
                    }))}
                />
            </Form.Item>
            <Form.Item
                label={'Принтеры'}
                name="printers_id"
                style={{ width: '100%' }}
            >
                <Select
                    placeholder="Выберите принтеры"
                    style={{ width: '100%' }}
                    mode="multiple"
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={printers.map(el => ({
                        value: el.id,
                        label: el.value,
                    }))}
                />
            </Form.Item>
            <Form.Item
                label={'Адрес'}
                name="address"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Input placeholder='Введите адрес' />
            </Form.Item>
            <div className='locationBlock'>
                <div className='locationBlock_map'>
                    <Map
                        defaultState={{
                            center: [55.751574, 37.573856],
                            zoom: 15,
                            type: 'yandex#hybrid'
                        }}
                        width='100%'
                        height='100%'
                        onClick={(e) => {
                            setCoords(e.get('coords'))
                        }}
                    >
                        <FullscreenControl />
                        <SearchControl options={{ float: "right" }} />
                        <GeolocationControl options={{ float: "left" }} />
                        <TypeSelector options={{ float: 'right' }} />
                        {coords && <Placemark geometry={coords} />}
                    </Map>
                </div>
            </div>
            <Form.Item>
                <FormButton
                    title={'Создать'}
                    htmlType="submit"
                    style={{ marginTop: 16 }}
                />
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        materials: state.materials.materials,
        printers: state.printers.printers,
        roles: state.roles.roles
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchCreateUser: (user_data) => dispatch(fetchCreateUser(user_data)),
        fetchTogglePrinters: (user_id, printer_id) => dispatch(fetchTogglePrinters(user_id, printer_id)),
        fetchToggleMaterials: (user_id, material_id) => dispatch(fetchToggleMaterials(user_id, material_id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateVenderForm);