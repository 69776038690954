import {
    ADMIN_FETCH_ORDER_BY_ID_DATA_START,
    ADMIN_FETCH_ORDER_BY_ID_DATA_SUCCESS,
    ADMIN_CLEAR_ORDER_BY_ID_DATA,
} from '../../actionTypes'

const initialState = {
    order_by_id_data: null,
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case ADMIN_FETCH_ORDER_BY_ID_DATA_START:
            return {
                ...state,
            }
        case ADMIN_FETCH_ORDER_BY_ID_DATA_SUCCESS:
            return {
                ...state,
                order_by_id_data: action.data.order_by_id_data,
            }
        case ADMIN_CLEAR_ORDER_BY_ID_DATA:
            return {
                order_by_id_data: null,
            }
        default: return state
    }
}