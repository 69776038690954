import Filter from 'components/shared/filter/Filter'
import Pagination from 'components/shared/pagination/Pagination'
import Search from 'components/shared/search/Search'
import Table from 'components/shared/table/Table'
import TextHeading from 'components/ui/teaxtHeading/TextHeading'
import { adminOrdersFilter } from 'data/filterLIst'
import { ordersStatus } from 'data/statuses'
import { allOrdersTable } from 'data/tableHeader'
import { allOrdersTableWidth } from 'data/tableWidth'
import { createFio } from 'hooks/createFio'
import { createQuerySecondType, getQueryStringFromObject } from 'hooks/createQuery'
import { formatDateTime } from 'hooks/formatDate'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { fetchAllOrders } from 'store/actions/admin/orders'

const AllOrdersAdmin = ({ orders, pages, fetchAllOrders }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState('')
    const [searchExecutor, setSearchExecutor] = useState('')
    const navigate = useNavigate()
    
    useEffect(() => {
        const query = Object.fromEntries([...searchParams]);
        if (query.page !== undefined) {
            fetchAllOrders(
                query.page,
                50,
                createQuerySecondType(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order)
            )
        } else {
            query.page = 1
            setSearchParams(query)
        }
    }, [
        Object.fromEntries([...searchParams]).page,
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).order_by,
        Object.fromEntries([...searchParams]).order
    ])
    
    const createTableData = () => {
        return orders.map(el => (
            [
                {
                    type: 'active_text',
                    content: <span onClick={() => navigate(`/admin/orders/${el.id}`)}>{el.id}</span>
                },
                {
                    type: 'active_text',
                    content: el.external_id ? <a className='activeText' href={`${process.env.REACT_APP_WordPressAdminUrl}post.php?post=${el.external_id}&action=edit`} target='_blank'>{el.external_id}</a> : '-'
                },
                {
                    type: 'text',
                    content: formatDateTime(el.time_created)
                },
                {
                    type: 'text',
                    content: el.price
                },
                {
                    type: 'text',
                    content: createFio(el.customer)
                },
                {
                    type: 'text',
                    content: el.customer.email || '-'
                },
                {
                    type: 'text',
                    content: el.customer.phone || '-'
                },
                {
                    type: 'actions',
                    content: <div className='statusOnTable p12_400' style={{ backgroundColor: ordersStatus[el.status].bg }}>{ordersStatus[el.status].title}</div>
                },
            ]
        ))

    }

    const onSearch = (e, type) => {
        const query = Object.fromEntries([...searchParams]);
        if (type === 'id' && !/[,.*^&?%$#!=+-]/.test(e)) {
            if (query.search) {
                const obj = JSON.parse(decodeURIComponent(((query.search))))
                obj.external_id = parseInt(e)
                query.search = getQueryStringFromObject(JSON.stringify(obj))
            } else {
                query.search = getQueryStringFromObject(JSON.stringify({ "external_id": parseInt(e) }))
            }
            setSearch(e)
        } else if (type === 'executor') {
            if (query.search) {
                const obj = JSON.parse(decodeURIComponent(((query.search))))
                obj.vendor_name = e
                query.search = getQueryStringFromObject(JSON.stringify(obj))
            } else {
                query.search = getQueryStringFromObject(JSON.stringify({ "vendor_name": e }))
            }
            setSearchExecutor(e)
        }
        setSearchParams(query)
    }

    return (
        <div className='contentBlock'>
            <div className='contentBlock_header'>
                <div className='contentBlock_header__leftHalf'>
                    <TextHeading text={'Заказы'} />
                    <Search
                        placeholder={"Поиск по внешнему ID"}
                        className={'search_marginLeft'}
                        value={search}
                        setValue={(e) => onSearch(e, 'id')}
                    />
                </div>
                <div className='contentBlock_header__rightHalf' style={{ gap: '12px' }}>
                    {/* <Search
                        placeholder={"Поиск по исполнителю"}
                        value={searchExecutor}
                        setValue={(e) => onSearch(e, 'executor')}
                    /> */}
                    <Filter
                        filterList={adminOrdersFilter}
                        blockFilterList={['order_id', 'vendor_name', 'external_id']}
                    />
                    {/* <SimpleButton
                        title={'Создать заказ'}
                        className="blueButton"
                        onClick={() => navigate(`/admin/orders/create`)}
                    /> */}
                </div>
            </div>
            <Table
                header={allOrdersTable}
                width={allOrdersTableWidth}
                data={createTableData()}
            />
            {orders.length > 0 && <Pagination pages={pages} />}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        orders: state.adminOrders.orders,
        pages: state.adminOrders.pages
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchAllOrders: (page, size, params) => dispatch(fetchAllOrders(page, size, params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllOrdersAdmin)