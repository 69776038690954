import { Checkbox, Form, Input, Upload } from 'antd';
import uploadIcon from 'assets/images/icons/upload.svg';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import UploadCard from 'components/shared/cards/uploadCard/UploadCard';
import { errorNotification } from 'hooks/notifications';
import { useState } from 'react';
import { connect } from 'react-redux';
import { CLOSE_MODAL } from 'store/actionTypes';
import { fetchCompliteOrderById } from 'store/actions/user/orders';

const propsDocsUpload = {
    action: '',
    accept: ".png, .jpg, .jpeg",
    listType: "text",
    multiple: true,
};

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

function OrderCompleteForm({ reject, defData, data, setVisible, fetchCompliteOrderById }) {
    const [fileDocsList, setFileDocsList] = useState([])
    const [fields, setFields] = useState([

    ]);

    const onFinish = ({ confirm, confirm2, vendor_description }) => {
        let requestBody = new FormData();

        if (!fileDocsList.length) {
            errorNotification('Отсутствуют файлы!', 'Для продолжения необходимо прикрепить файлы')
            return
        }
        if (!confirm && !confirm2) {
            errorNotification('Отсутствуют подтверждение!', 'Для продолжения необходимо подтвердить, что изделие соответствует качеству приемки!')
            return
        }
        for (let i = 0; i < fileDocsList.length; i++) {
            let filedata = fileDocsList[i].originFileObj
            delete filedata.uid
            requestBody.append('images', filedata);
        }
        requestBody.append('vendor_description', vendor_description)
        fetchCompliteOrderById(data.id, requestBody)
        setVisible()
    };

    const normFile = (e, type) => {
        setFileDocsList(e.fileList)

        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const deleteFile = (index, type) => {
        const files = [...fileDocsList];
        files.splice(index, 1);
        setFileDocsList(files);

    }

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <Form.Item
                label='Описание'
                name="vendor_description"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
                style={{ width: '100%' }}
            >
                <Input placeholder='Введите описание' />
            </Form.Item>
            <div style={{ marginTop: 24 }}>
                <div className={`${fileDocsList.length === 0 ? '' : 'squareUploadBlock'}`}>
                    <div className='squareUploadBlock'>
                        {fileDocsList.map((el, index) => (
                            <UploadCard key={`UploadCard_${index}`} title={el.name || el.media.title} onClick={() => deleteFile(index, 'fileDocsList')} />
                        ))}
                        <Form.Item
                            name="filePhotoList"
                            valuePropName="filePhotoList"
                            getValueFromEvent={(e) => normFile(e, 'fileDocsList')}
                            className={`${fileDocsList.length === 0 ? '' : 'squareUpload'}`}
                            style={{width: '100%'}}
                        >
                            {fileDocsList.length === 0
                                ?
                                <Upload.Dragger
                                    name="fileDocsList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsDocsUpload}
                                >
                                    <div style={{ width: '100%', margin: '100px auto' }}>
                                        <p className="subtext upload-text">Перетащите сюда, чтобы загрузить файл</p>
                                        <div className='uploadButton whiteButtonText'>Загрузить файл</div>
                                    </div>
                                </Upload.Dragger>
                                :
                                <Upload.Dragger
                                    name="fileDocsList"
                                    action="/upload.do"
                                    itemRender={null}
                                    customRequest={dummyRequest}
                                    {...propsDocsUpload}
                                    fileList={fileDocsList}
                                >
                                    <img src={uploadIcon} alt="uploadIcon" />
                                </Upload.Dragger>
                            }
                        </Form.Item>
                    </div>
                </div>
            </div>
            <Form.Item
                name="confirm"
                valuePropName="checked"
                style={{ marginBottom: 0 }}
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Checkbox>Я проверил изделие на <a href='https://studia3d.com/aggregator/connect/specification/ ' target='_blank' className='activeText'>требования качества приемки</a>.</Checkbox>
            </Form.Item>
            <Form.Item
                name="confirm2"
                valuePropName="checked"
                style={{ marginBottom: 10 }}
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
            >
                <Checkbox>Подтверждаю, что изделие соответствует требованиям качества.</Checkbox>
            </Form.Item>
            <Form.Item
                style={{ marginBottom: 0 }}
            >
                <div>
                    <SimpleButton
                        title={'Отмена'}
                        className="activeText p14_500 whiteButton"
                        style={{
                            marginRight: 16
                        }}
                        onClick={setVisible}
                    />
                    <SimpleButton
                        title={'Подтверждаю выполнение'}
                        className="p14_500 blueButton"
                        htmltype="submit"
                    />
                </div>
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        fetchCompliteOrderById: (order_id, data) => dispatch(fetchCompliteOrderById(order_id, data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCompleteForm);