import { ApiUrl, instance } from '../apiEnv';

export const api_users = {

    async GetVenders(page, size, params, search_str) {
        return await instance.get(`${ApiUrl}user/list/by_role_name?role_name=vendor&page=${page}&size=${size}${search_str ? `&search_str=${search_str}` : ''}`)
    },

    async GetUserById(user_id) {
        return await instance.get(`${ApiUrl}user/${user_id}`)
    },

    async UpdateUser(user_id, user_data) {
        return await instance.put(`${ApiUrl}user/${user_id}`, user_data)
    },

    async CreateUser(user_data) {
        return await instance.post(`${ApiUrl}user`, user_data)
    },

    async DeleteUser(user_id) {
        return await instance.delete(`${ApiUrl}user/${user_id}`)
    },

    async PatchTogglePrinters(user_id, printer_id) {
        return await instance.patch(`${ApiUrl}user/${user_id}/toggle_printers/${printer_id}`)
    },

    async PatchToggleMaterials(user_id, material_id) {
        return await instance.patch(`${ApiUrl}user/${user_id}/toggle_materials/${material_id}`)
    },

    async PatchUserPassword(password, user_id) {
        return await instance.patch(`${ApiUrl}user?user_id=${user_id}&password=${password}`)
    },

}
