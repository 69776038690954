import OrderCompleteForm from "components/components/forms/modal/orderComplete/OrderCompleteForm"

const OrderComplete = () => {
  return (
    <div style={{maxWidth: 657}}>
        <p className="mainText p24_600 p_mb8" style={{ textAlign: 'center' }}>Заказ выполнен</p>
        <p className="subtext p_mb16">Для отправки заказа на проверку, необходимо добавить фотографии выполненной работы</p>
        <OrderCompleteForm />
    </div>
  )
}

export default OrderComplete