import AcceptVerificationOrderForm from "components/components/forms/modal/verificationOrder/acceptVerificationOrder/AcceptVerificationOrderForm"

const AcceptVerificationOrder = () => {
  return (
    <div>
        <p className="mainText p24_600 p_mb8" style={{ textAlign: 'center', width: '30vw' }}>Подтверждение выполненной работы</p>
        <AcceptVerificationOrderForm />
    </div>
  )
}

export default AcceptVerificationOrder