export const allOrdersTable = [
    {
        title: 'ID заказа',
    },
    {
        title: 'Внешний ID заказа',
    },
    {
        title: 'Дата создания',
    },
    {
        title: 'Цена',
    },
    {
        title: 'ФИО заказчика',
    },
    {
        title: 'Email заказчика',
    },
    {
        title: 'Номер телефона заказчика',
    },
    {
        title: 'Статус',
    },
    {
        title: '',
    },
]

export const allUserOrdersTable = [
    {
        title: 'ID заказа',
    },
    {
        title: 'Дата создания',
    },
    {
        title: 'Цена',
    },
    // {
    //     title: 'Время печати',
    // },
    {
        title: 'Статус',
    },
    {
        title: '',
    },
]

export const allUserOffersTable = [
    {
        title: 'ID заказа',
    },
    {
        title: 'Дата создания',
    },
    // {
    //     title: 'Цена',
    // },
    {
        title: 'Место в очереди',
    },
    {
        title: 'Время печати',
    },
    {
        title: 'Статус',
    },
    {
        title: '',
    },
]


export const allContractorsTable = [
    {
        title: 'Дата и время',
    },
    {
        title: 'ID сделки',
    },
    {
        title: 'ФИО заказчика',
    },
    {
        title: 'ФИО исполнителя',
    },
    {
        title: 'Причина',
    },
    {
        title: '',
    },
]

export const productsInOrderTable = [
    {
        title: 'Материал',
    },
    {
        title: 'Цвет',
    },
    {
        title: 'Заполнение',
    },
    {
        title: 'Принтер',
    },
    {
        title: 'Количество',
    },
    // {
    //     title: 'Цена',
    // },
    {
        title: '3D модель',
    },
]

export const distributionsInOrderTable = [
    {
        title: 'Email',
    },
    {
        title: 'Время отправки Email',
    },
    {
        title: 'Причина отказа',
    },
    {
        title: 'Статус',
    },
    {
        title: '',
    },
]

export const createDistributionTable= [
    {
        title: 'ФИО',
    },
    {
        title: 'Email',
    },
    {
        title: 'Номер телефона',
    },
    {
        title: 'Адрес',
    },
    {
        title: 'Доступные принтеры',
    },
    {
        title: 'Доступные материалы',
    },
    {
        title: '',
    },
]

export const reviewInOrderTable = [
    {
        title: 'ID исполнителя',
    },
    {
        title: 'Ответ менеджера',
    },
    {
        title: 'Комментарий исполнителя',
    },
    {
        title: 'Статус',
    },
    {
        title: '',
    },
]

export const allVendorsTable = [
    {
        title: 'ID',
    },
    {
        title: 'ФИО',
    },
    {
        title: 'Email',
    },
    {
        title: 'Номер телефона',
    },
    {
        title: 'Материалы',
    },
    {
        title: 'Принтеры',
    },
    {
        title: 'Активный',
    },
    {
        title: 'Верифицирован',
    },
    {
        title: '',
    },
]

export const allUserFinanceTable = [
    {
        title: 'ID заказа',
    },
    {
        title: 'Дата поступления',
    },
    {
        title: 'Сумма',
    },
    {
        title: 'Тип операции',
    },
    {
        title: 'Статус',
    },
]

export const allAdminFinanceTable = [
    {
        title: 'ID заказа',
    },
    {
        title: 'Email пользователя',
    },
    {
        title: 'Дата поступления',
    },
    {
        title: 'Сумма',
    },
    {
        title: 'Тип операции',
    },
    // {
    //     title: 'ИНН',
    // },
    // {
    //     title: 'КПП',
    // },
    // {
    //     title: 'ОГРН',
    // },
    // {
    //     title: 'Банк',
    // },
    // {
    //     title: 'БИК банк',
    // },
    // {
    //     title: 'Кор. счет',
    // },    
    {
        title: 'Статус',
    },
    {
        title: '',
    },
]

export const documentsInOrderTable = [
    {
        title: 'Название',
    },
    {
        title: 'Тип',
    },
    {
        title: '',
    },
]