import { Form, Input } from 'antd';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CLOSE_MODAL } from 'store/actionTypes';
import { fetchRejectOrderById } from 'store/actions/user/orders';


function OrderRejectForm({ data, setVisible, fetchRejectOrderById }) {
    const navigate = useNavigate()
    const [fields, setFields] = useState([
        {
            name: 'rejection_reason',
            value: ''
        }
    ]);

    const onFinish = async ({ rejection_reason }) => {
        await fetchRejectOrderById(data.order_id, rejection_reason)
            .then(res => {
                if (res) {
                    setVisible()
                    navigate(-1)
                }
            })
    };

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <Form.Item
                label='Причина отказа'
                name="rejection_reason"
                rules={[
                    {
                        required: true,
                        message: 'Это обязательное поле',
                    },
                ]}
                style={{ width: '100%' }}
            >
                <Input placeholder='Введите причину отказа' />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: 0 }}
            >
                <div>
                    <SimpleButton
                        title={'Отмена'}
                        className="activeText p14_500 whiteButton"
                        style={{
                            marginRight: 16
                        }}
                        onClick={setVisible}
                    />
                    <SimpleButton
                        title={'Отказаться от предложения'}
                        className="p14_500 blueButton"
                        htmltype="submit"
                    />
                </div>
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
        venders: state.venders.venders,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        fetchRejectOrderById: (order_id, rejection_reason) => dispatch(fetchRejectOrderById(order_id, rejection_reason)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderRejectForm);