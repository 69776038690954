import {
    FETCH_LOGIN_START,
    FETCH_LOGIN_SUCCESS,
    FETCH_REFRESH_TOKEN_SUCCESS,
    FETCH_LOGIN_ERROR,
    SAVE_DATA_FOR_ACTIVE,
    EXIT
} from '../actionTypes'

const LocalToken = localStorage.getItem('studia3d-agregator-token');
const LocalRefreshToken = localStorage.getItem('studia3d-agregator-refresh-token');
const LocalRole = localStorage.getItem('studia3d-agregator-user-role');

const initialState = {
    access_token: LocalToken,
    refresh_token: LocalRefreshToken,
    role: LocalRole,
    link: null,
    loading: false,
    saveDataForActive: null
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_LOGIN_START:
            return {
                ...state, loading: true
            }
        case FETCH_LOGIN_SUCCESS:
            localStorage.setItem('studia3d-agregator-token', action.data.access_token)
            localStorage.setItem('studia3d-agregator-refresh-token', action.data.refresh_token)
            localStorage.setItem('studia3d-agregator-user-role', action.data.role)
            return {
                ...state,
                loading: false,
                access_token: action.data.access_token,
                refresh_token: action.data.refresh_token,
                role: action.data.role,
            }
        case FETCH_REFRESH_TOKEN_SUCCESS:
            localStorage.setItem('studia3d-agregator-token', action.data.access_token)
            return {
                ...state,
                loading: false,
                access_token: action.data.access_token,
            }
        case FETCH_LOGIN_ERROR:
            return {
                ...state, loading: false
            }
        case SAVE_DATA_FOR_ACTIVE:
            return {
                ...state,
                saveDataForActive: action.data
            }
        case EXIT:
            localStorage.removeItem('studia3d-agregator-token');
            localStorage.removeItem('studia3d-agregator-refresh-token');
            localStorage.removeItem('studia3d-agregator-user-role');
            return {
                ...state,
                access_token: null,
                refresh_token: null,
                role: null,
            }
        default: return state
    }
}