import { Checkbox, Form } from 'antd';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import Table from 'components/shared/table/Table';
import { createDistributionTable } from 'data/tableHeader';
import { createDistributionTableWidth } from 'data/tableWidth';
import { errorNotification, successNotification } from 'hooks/notifications';
import { useState } from 'react';
import { connect } from 'react-redux';
import { CLOSE_MODAL } from 'store/actionTypes';
import { fetchCreateDistribution } from 'store/actions/admin/distribution';
import { fetchOrderById } from 'store/actions/admin/orders';
import './style.css';

function CreateDistributionForm({ data, venders, setVisible, fetchCreateDistribution, fetchOrderById }) {
    const [fields, setFields] = useState([
        {
            name: 'distributions_id',
            value: []
        }
    ]);

    const onFinish = async ({ distributions_id }) => {
        let successCount = 0
        if (distributions_id.length) {
            for (let i = 0; i < distributions_id.length; i++) {
                await fetchCreateDistribution({
                    email: venders.find(o => o.id === distributions_id[i]).email,
                    order_id: data.order_id,
                    vendor_id: distributions_id[i]
                }).then(res => {
                    if (res) {
                        successCount++
                    }
                })
            }

            if (successCount === distributions_id.length) {
                successNotification('Исполнитель добавлен в очередь', 'Исполнитель успешно добавлен в очередь')
                fetchOrderById(data.order_id)
            } else {
                errorNotification('Возникла ошибка')
            }
        }
        setVisible()
    };

    const createDistributionsTableData = () => {
        const array = []
        venders.forEach(el => {
            array.push(
                [
                    {
                        type: 'text',
                        content: el.first_name + ' ' + el.last_name
                    },
                    {
                        type: 'text',
                        content: el.email || '-'
                    },
                    {
                        type: 'text',
                        content: el.phone || '-'
                    },
                    {
                        type: 'text',
                        content: el.address || '-'
                    },
                    {
                        type: 'text',
                        content: <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap'
                            }}
                        >{
                                el.printers.length
                                    ? el.printers.map((printer, key) => {
                                        if (key === el.printers.length - 1) {
                                            return <span>{printer.value}</span>
                                        } else {
                                            return <span>{printer.value + ', '}</span>
                                        }
                                    })
                                    : '-'
                            }</div>
                    },
                    {
                        type: 'text',
                        content: <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap'
                            }}
                        >{
                                el.materials.length
                                    ? el.materials.map((material, key) => {
                                        if (key === el.materials.length - 1) {
                                            return <span>{material.value}</span>
                                        } else {
                                            return <span>{material.value + ', '}</span>
                                        }
                                    })
                                    : '-'
                            }</div>
                    },
                    {
                        type: 'actions',
                        content: <div style={{ textAlign: 'end' }}>
                            <Checkbox
                                value={el.id}
                                style={{
                                    lineHeight: '32px',
                                }}
                            />
                        </div>
                    },
                ]
            )
        })
        return array
    }

    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <div className='tableScrollBlock'>
                <Form.Item
                    label=''
                    name="distributions_id"
                    style={{ width: '100%' }}
                >
                    <Checkbox.Group>
                        <Table
                            header={createDistributionTable}
                            width={createDistributionTableWidth}
                            data={createDistributionsTableData()}
                        />
                    </Checkbox.Group>
                </Form.Item>
            </div>
            <Form.Item
                style={{ marginBottom: 0 }}
            >
                <div>
                    <SimpleButton
                        title={'Отмена'}
                        className="activeText p14_500 whiteButton"
                        style={{
                            marginRight: 16
                        }}
                        onClick={setVisible}
                    />
                    <SimpleButton
                        title={'Добавить'}
                        className="p14_500 blueButton"
                        htmltype="submit"
                    />
                </div>
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        data: state.modal.data,
        venders: state.venders.venders,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        fetchOrderById: (id) => dispatch(fetchOrderById(id)),
        fetchCreateDistribution: (data) => dispatch(fetchCreateDistribution(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDistributionForm);