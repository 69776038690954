import { api_users } from 'api/admin/users';
import { errorNotification, successNotification } from 'hooks/notifications';
import {
    ADMIN_FETCH_USER_BY_ID_DATA_START,
    ADMIN_FETCH_USER_BY_ID_DATA_SUCCESS,
    FETCH_ALL_VENDERS_START,
    FETCH_ALL_VENDERS_SUCCESS,
    FETCH_ALL_VENDERS_SUCCESS_CONCAT,
} from '../../actionTypes';
import { fetchStart, fetchSuccess } from '../fetchStatuses';

export function fetchAllVendersWithoutConcat(page, size, params, search_str) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_VENDERS_START))
        try {
            const venders = await api_users.GetVenders(page, size, params, search_str)
            dispatch(fetchSuccess(FETCH_ALL_VENDERS_SUCCESS, {
                data: venders.data.data
            }))
        } catch (e) {
        }
    }
}

export function fetchAllVenders(page, size, params, search_str) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_VENDERS_START))
        try {
            const venders = await api_users.GetVenders(page, size, params, search_str)
            dispatch(fetchSuccess(FETCH_ALL_VENDERS_SUCCESS, {
                data: venders.data.data
            }))
            if (venders.data.data.next_page) {
                dispatch(fetchAllVendersConcat(venders.data.data.next_page, size, params, search_str))
            }
        } catch (e) {
        }
    }
}

export function fetchAllVendersConcat(page, size, params, search_str) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_VENDERS_START))
        try {
            const venders = await api_users.GetVenders(page, size, params, search_str)
            dispatch(fetchSuccess(FETCH_ALL_VENDERS_SUCCESS_CONCAT, {
                data: venders.data.data
            }))
            if (venders.data.data.next_page) {
                dispatch(fetchAllVendersConcat(venders.data.data.next_page, size, params, search_str))
            }
        } catch (e) {
        }
    }
}

export function fetchUserById(user_id) {
    return async dispatch => {
        dispatch(fetchStart(ADMIN_FETCH_USER_BY_ID_DATA_START))
        try {
            const user_data = await api_users.GetUserById(user_id)
            dispatch(fetchSuccess(ADMIN_FETCH_USER_BY_ID_DATA_SUCCESS, {
                user_by_id_data: user_data.data.data
            }))
        } catch (e) {

        }
    }
}

export function fetchUpdateUser(user_id, user_data) {
    return async dispatch => {
        try {
            const user = await api_users.UpdateUser(user_id, user_data)
            dispatch(fetchUserById(user_id))
            return user.data.data
        } catch (e) {
            errorNotification('Возникла ошибка')
            return false
        }
    }
}

export function fetchCreateUser(user_data) {
    return async dispatch => {
        try {
            const user = await api_users.CreateUser(user_data)
            successNotification('Исполнитель создан', 'Исполнитель успешно создан')
            return user.data.data
        } catch (e) {
            if (e.response.data.detail === "There is already a user with same email") {
                errorNotification('Возникла ошибка', 'Пользователь с таким email уже существует')
            } else {
                errorNotification('Возникла ошибка')
            }
            return false
        }
    }
}

export function fetchDeleteUser(user_id) {
    return async dispatch => {
        try {
            await api_users.DeleteUser(user_id)
            successNotification('Исполнитель удален', 'Исполнитель успешно удален')
        } catch (e) {
            errorNotification('Возникла ошибка')
        }
    }
}

export function fetchTogglePrinters(user_id, printer_id) {
    return async dispatch => {
        try {
            await api_users.PatchTogglePrinters(user_id, printer_id)
            return true
        } catch (e) {
            errorNotification('Возникла ошибка')
            return false
        }
    }
}

export function fetchToggleMaterials(user_id, material_id) {
    return async dispatch => {
        try {
            await api_users.PatchToggleMaterials(user_id, material_id)
            return true
        } catch (e) {
            errorNotification('Возникла ошибка')
            return false
        }
    }
}

export function fetchChangePassword(password, user_id) {
    return async dispatch => {
        try {
            await api_users.PatchUserPassword(password, user_id)
            return true
        } catch (e) {
            errorNotification('Возникла ошибка')
            return false
        }
    }
}