import axios from 'axios';

const LocalToken = localStorage.getItem('studia3d-agregator-token');
let token = LocalToken;

export let instance = axios.create({
    headers: { 
        Authorization: `Bearer ${token}`
    }
})

export const ApiUrl = process.env.REACT_APP_ApiUrl

export const initToken = () => {
    const LocalToken = localStorage.getItem('studia3d-agregator-token');
    token = LocalToken;

    instance = axios.create({
        headers: { Authorization: `Bearer ${token}` }
    })
}