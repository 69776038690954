import { Form, InputNumber } from 'antd';
import SimpleButton from 'components/shared/buttons/simpleButton/SimpleButton';
import { createQuery } from 'hooks/createQuery';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { CLOSE_MODAL } from 'store/actionTypes';
import { fetchAllOperations, fetchCreateOperation } from 'store/actions/user/operations';


function CreateOperationForm({ user_data, setVisible, fetchCreateOperation, fetchAllOperations }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [fields, setFields] = useState([
        {
            name: 'amount',
            value: ''
        }
    ]);

    const onFinish = async ({ amount }) => {
        const query = Object.fromEntries([...searchParams]);
        await fetchCreateOperation(amount)
        await fetchAllOperations(
            query.page,
            50,
            createQuery(Object.fromEntries([...searchParams]).search, Object.fromEntries([...searchParams]).order_by, Object.fromEntries([...searchParams]).order)
        )
        setVisible()
    };
    
    return (
        <Form
            layout="vertical"
            requiredMark={false}
            initialValues={{
                remember: true,
            }}
            fields={fields}
            onFieldsChange={(_, allFields) => {
                setFields(allFields)
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="modalForm"
        >
            <Form.Item
                label='Сумма операции'
                name="amount"
                rules={[
                    {
                        required: true,
                        message: `Сумма не должна быть меньше 1000₽ и не должна быть больше ${(Math.floor(user_data.balance)).toFixed(0)}₽`,
                    },
                ]}
                style={{ width: '100%' }}
            >
                <InputNumber placeholder='Введите сумму операции' max={(Math.floor(user_data.balance)).toFixed(0)} min={1000} />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: 0 }}
            >
                <div>
                    <SimpleButton
                        title={'Отмена'}
                        className="activeText p14_500 whiteButton"
                        style={{
                            marginRight: 16
                        }}
                        onClick={setVisible}
                    />
                    <SimpleButton
                        title={'Создать операцию'}
                        className="p14_500 blueButton"
                        htmltype="submit"
                    />
                </div>
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(state) {
    return {
        user_data: state.user.user_data,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
        fetchCreateOperation: (amount) => dispatch(fetchCreateOperation(amount)),
        fetchAllOperations: (page, size, params) => dispatch(fetchAllOperations(page, size, params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOperationForm);